import React, { useEffect, useState } from "react";
import ScreenTitle from "../../../../../component/screen_title";
import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import ErrorLabel from "../../../../../component/form/error_label";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  ERROR,
  PRIMARY_COLOR,
  UNAUTHORIZED,
  CLAIM_FILE_ERROR_MESSAGE,
  CLAIM_FILE_SIZE,
} from "../../../../../constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import { withAuth } from "react-auth-guard";
import { toast } from "react-toastify";
import { KEY_TOAST_MESSAGE, setData } from "../../../../../utils/preferences";
import { DateBox, TagBox } from "devextreme-react";
import dateFormat from "dateformat";
import { isEqual, random } from "lodash";
import ArrayStore from "devextreme/data/array_store";
import { FiEdit, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import {
  actionProposalPoolEdit,
  ACTION_PROPOSALPOOL_EDIT_SUCCESS,
} from "./action";
import {
  actionProposalStatus,
  ACTION_OPPORTUNITY_STATUS_SUCCESS,
} from "./action";
import {
  actionProposalQuoteRejectStatus,
  ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS,
} from "./action";
import {
  actionProposalLostStatus,
  ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS,
} from "./action";
import {
  actionProposalGetPlacements,
  ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS,
} from "./action";
import {
  ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS,
  actionPolicyGroup,
} from "./action";
import {
  ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS,
  actionInsuranceCompanyGroup,
} from "./action";
import {
  ACTION_INTERMEDIARY_TYPE_SUCCESS,
  actionIntermediaryType,
} from "./action";
import {
  ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS,
  actionGetBrokerName,
} from "./action";
import {
  ACTION_MASTER_PROBABILITY_SUCCESS,
  actionProbabilityGroup,
} from "./action";
import {
  ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS,
  actionOpportunityQuestionsList,
} from "./action";
import {
  ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS,
  actionGetOpportunityQuestions,
} from "./action";
import { ACTION_GET_CLOSURE_TYPE_SUCCESS, actionClosureType } from "./action";
import { actionDeleteDocument, ACTION_DELETE_DOCUMENT_SUCCESS } from "./action";
import {
  actionProposalPoolView,
  ACTION_PROPOSALPOOL_VIEW_SUCCESS,
} from "./action";
import {
  actionGetAssignProductRM,
  ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS,
} from "./action";
import {
  actionInsuranceListForSuccess,
  ACTION_INSURANCE_SUCCESS_LIST_SUCCESS,
} from "./action";
import { actionInstallment, ACTION_INSTALLMENT_SUCCESS } from "./action";
import {
  ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS,
  actionDepartmentInsuranceCompanyGroup,
} from "./action";
import {
  verifyPermission,
  checkFileExtension,
  CalculateProposalMonth,
} from "../../../../../utils";
import AddOpportunityPlacement from "./placement_popup";
import DataGrid, {
  Button,
  Column,
  FilterRow,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Summary,
  SearchPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import _ from "lodash";
import { IoIosArrowDown } from "react-icons/io";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import { downloadFile } from "../../../../../utils";
import DocumentIframe from "../../../../../component/popups/document_iframe";
import { actionTagUserList, ACTION_GET_TAG_USER_SUCCESS } from "../../../../../component/form/policy_details/action";

const AllProposalEdit = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      // client_remark: '',
      department_name: "",
      opportunityQuestion: [],
      product_specialist_id: 0,
      cgst: 9,
      sgst: 9,
    },
  });
  const { fields: opportunityQuestionFields } = useFieldArray({
    control,
    name: "opportunityQuestion",
  });

  const [files, setFiles] = useState([]);
  const addQuestion = false;
  const [resetData, setResetData] = useState(false);
  const [type, setType] = useState([]);
  const [totalSharePercent, setTotalSharePercent] = useState();
  const [totalSharePremiumValue, setTotalSharePremiumValue] = useState();
  const [shareODPremium, setShareODPremium] = useState(0);
  const [totalGrossPremiumValue, setTotalGrossPremiumValue] = useState();
  const [coInsurerGetInsurer, SetCoInsurerGetInsurer] = useState([]);
  const validExt = ["doc", "docx", "xlsx", "xls", "pdf", "zip"];
  const [getBrokerName, setGetBrokerName] = useState([]);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [departmentDetails, setDepartmentDetails] = useState(null);
  const [opportunityType, setOpportunityType] = useState(null);
  const [intermediaryType, setIntermediaryType] = useState(null);
  const [probabilityGroup, setProbabilityGroup] = useState([]);
  const [probability, setProbability] = useState(null);
  const [policyGroup, setPolicyGroup] = useState([]);
  const [closureType, setClosureType] = useState(null);
  const [brokeragePaidBy, setBrokeragePaidBy] = useState(null);
  const [insuranceCompanyGroup, setInsuranceCompanyGroup] = useState([]);
  const [departmentWiseInsurer, setDepartmentWiseInsurer] = useState([]);
  const [arrSystemProbability, setArrSystenProbability] = useState([]);
  const [isOpportunityQuestion, setIsOpportunityQuestion] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState(null);
  const [closureTypeList, setClosureTypeList] = useState(null);
  const [businessClosureDate, setBusinessClosureDate] = useState(null);
  const [proposalLostReason, setProposalLostReason] = useState(null);
  const [quoteStatusDropdown, QuoteStatusDropdown] = useState([]);
  const [quoteRejectStatusDropdown, QuoteRejectStatusDropdown] = useState([]);
  const [lostStatusDropdown, SetLostStatusDropdown] = useState([]);
  const [getProposalPlacement, SetGetProposalPlacement] = useState([]);
  const [placementData, setPlacementData] = useState(null);
  const [showAddPlacement, setShowAddPlacement] = useState(false);
  const [department, setDepartment] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [premium, setPremium] = useState(0);
  const [numberInWords, setNumberInWords] = useState(0);
  const [intermediaryTypeList, SetIntermediaryTypeList] = useState(null);
  const [questionList, setQuestionList] = useState([]);
  const [competitionIntermediaryType, setCompetitionIntermediaryType] =
    useState(null);
  const watchNetAmount = watch("net_amount");
  const watchNetPremium =watch('total_net_premium');
  const watchShareODPremium = watch("share_od_premium");
  const watchShareTPPremium = watch("share_tp_premium");
  const watchODPremium = watch("premium_amount");
  const watchODBrokeragePercent = watch("od_brokerage_percentage");
  const watchTPBrokeragePercent = watch("tp_brokerage_percentage");
  const watchTPPremium = watch("tp_net_premium");
  const watchTPBrokerage = watch("tp_brokerage_amt");
  const watchODBrokerage = watch("od_brokerage_amt");
  const watchGstAmount = watch("gst_amount");
  const watchCgstPercentage = watch("cgst");
  const watchSgstPercentage = watch("sgst");
  const watchPremiumAmount = watch("premium_amount");
  const watchPincShare = watch("pinc_share");
  const watchExpectedPremium = watch("expected_premium");
  const watchRewardOn = watch("reward_on");
  const watchRewardPercentage = watch("reward_percentage");
  const watchRewardAmount = watch("reward_amt");
  const watchBonusPercentage = watch("bonus_percentage");
  const watchBonusAmount = watch("bonus_amt");
  const [closureDate, setClosureDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [policyDate, setPolicyDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [policyEndDate, setPolicyEndDate] = useState();
  const [grossPremium, setGrossPremium] = useState(0);
  const [pincSharePremium, setPincSharePremium] = useState(0);
  const [proposalPoolViewDetails, setProposalPoolViewDetails] = useState();
  const [enabledValue, setEnabledValue] = useState();
  const [netPremium, setNetPremium] = useState(0);
  const [shareNetPremium, setShareNetPremium] = useState(0);
  const [policy, setPolicy] = useState("");
  const [getAssignProductRM, setGetAssignProductRM] = useState([]);
  const [detailsData, setDetailsData] = useState(null);
  const [showDocumentDetails, setShowDocumentDetails] = useState(false);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null);
  const [extension, setExtension] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [getInsuranceSuccessList, SetGetInsuranceSuccessList] = useState([]);
  const [placementId, setPlacementId] = useState();
  const [getTagUser, setTagUserList] = useState([]);
  const [policyDetails, setPolicyDetails] = useState([]);
  const [proposalProductRight, setProposalProductRight] = useState(null);
  const [opportunityInsurerId, setOpportunityInsurerId] = useState();
  const [opportunityId, setOpportunityId] = useState();
  const [installment, setInstallment] = useState([]);
  const [opportunityQuestionArrayList, setOpportunityQuestionArrayList] =
    useState([]);
  const userId = props.auth.getUser().user_id;

  const initialRender = () => {
    $("#progress-loader").removeClass("hidden");
    setDetailsData(JSON.parse(localStorage.getItem("detailsData")));
    props.actionProbabilityGroup();
    props.actionOpportunityQuestionsList({});
    props.actionProposalQuoteRejectStatus();
    props.actionProposalLostStatus();
    props.actionPolicyGroup({ status: "ACTIVE", orderBy: "name" });
    props.actionInsuranceCompanyGroup({ status: "ACTIVE" });
    props.actionIntermediaryType();
    props.actionGetBrokerName();
    props.actionTagUserList();
    props.actionClosureType();
    props.actionInstallment();
  };
  useEffect(initialRender, []);

  useEffect(() => {
    if (detailsData != null) {
      props.actionProposalPoolView({
        policy_id: detailsData.id,
        opportunity_status: detailsData.opportunity_status,
        closure_type: detailsData.closure_type,
      });
      props.actionProposalGetPlacements({ policy_id: detailsData.id });
      props.actionInsuranceListForSuccess({
        policy_id: detailsData.id,
        type: "ACTIVE",
      });
    }
  }, [detailsData]);

  const columns = [
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "insurer_name", caption: "Insurer Name", visible: true },
    { dataField: "branch_short_name", caption: "Branch Name", visible: true },
    { dataField: "status", caption: "Current Status", visible: true },
    { dataField: "quote_amount", caption: "Quote Amt (Net)", visible: true },
    {
      dataField: "quote_request_date",
      caption: "Quote Request Date",
      visible: true,
    },
    {
      dataField: "quote_received_date",
      caption: "Quote Receive Date",
      visible: true,
    },
    { dataField: "remark", caption: "Remarks", visible: true },
  ];

  const columnsInsurer = [
    { dataField: "Action", caption: "Action", type: "button" },
    { dataField: "insurer_name", caption: "Insurer Name", type: "button" },
    { dataField: "type", caption: "Type", visible: true },
    { dataField: "share", caption: "%Share", visible: true },
    {
      dataField: "share_premium_amount",
      caption: "Share Premium Amt",
      visible: true,
    },
    { dataField: "cgst", caption: "CGST%", visible: true },
    { dataField: "sgst", caption: "SGST%", visible: true },
    { dataField: "gross_premium", caption: "Gross Premium", visible: true },
  ];

  const columnsBroking = [
    { dataField: "broker_id", caption: "Broker Name", type: "button" },
    { dataField: "type", caption: "Type", visible: true },
    { dataField: "share", caption: "%Share", visible: true },
    {
      dataField: "share_premium_amount",
      caption: "Share Premium Amt",
      visible: true,
    },
    { dataField: "cgst", caption: "CGST%", visible: true },
    { dataField: "sgst", caption: "SGST%", visible: true },
    { dataField: "gross_premium", caption: "Gross Premium", visible: true },
  ];

  useEffect(() => {
    var grossPremiumAmount = 0;

    if (
      proposalPoolViewDetails?.opportunity_status === "Proposal Success" &&
      userId === proposalPoolViewDetails?.assigned_rm_id
    ) {
      if (watchCgstPercentage != null && watchSgstPercentage != null) {
        var cgstAmount =
          (Number(proposalPoolViewDetails?.closure?.net_premium) *
            Number(watchCgstPercentage)) /
          100;
        var sgstAmount =
          (Number(proposalPoolViewDetails?.closure?.net_premium) *
            Number(watchSgstPercentage)) /
          100;

        grossPremiumAmount =
          Number(proposalPoolViewDetails?.closure?.net_premium) +
          cgstAmount +
          sgstAmount;
      }
      setValue("gross_premium", grossPremiumAmount.toFixed(2));
      setGrossPremium(grossPremiumAmount);
    } else {
      if (watchCgstPercentage != null && watchSgstPercentage != null) {
        var cgstAmount =
          (Number(netPremium) * Number(watchCgstPercentage)) / 100;
        var sgstAmount =
          (Number(netPremium) * Number(watchSgstPercentage)) / 100;

        grossPremiumAmount =
          Number(netPremium) + cgstAmount + sgstAmount;
      }
      setValue("gross_premium", grossPremiumAmount.toFixed(2));

      setGrossPremium(grossPremiumAmount);
    }

    if (
      quoteStatus === "Proposal Success" &&
      closureType != null &&
      watchPremiumAmount != null &&
      Number(watchPremiumAmount) === 0 &&
      /^\d*\.?\d{0,2}$/.test(watchPremiumAmount)
    ) {
      $("#error-premium").removeClass("hidden");
      $("#editproposal_pool").prop("disabled", true);
    } else {
      $("#error-premium").addClass("hidden");
      $("#editproposal_pool").prop("disabled", false);
    }
  }, [watchCgstPercentage, watchSgstPercentage, watchPremiumAmount, netPremium]);

  useEffect(() => {
    var pincGrossPremiumAmount = 0;
    if (
      watchCgstPercentage != null &&
      watchSgstPercentage != null &&
      shareNetPremium != null
    ) {
      var cgstAmount =
        (Number(shareNetPremium) * Number(watchCgstPercentage)) / 100;
      var sgstAmount =
        (Number(shareNetPremium) * Number(watchSgstPercentage)) / 100;

      pincGrossPremiumAmount =
        Number(shareNetPremium) + cgstAmount + sgstAmount;
    }
    setValue("pinc_gross_premium", pincGrossPremiumAmount.toFixed(2));
  }, [watchCgstPercentage, watchSgstPercentage, shareNetPremium]);

  useEffect(() => {
    var pincSharePremiumAmount = 0;
    if (watchPincShare != null && watchPremiumAmount != null) {
      pincSharePremiumAmount =
        (Number(watchPremiumAmount) * Number(watchPincShare)) / 100;
    }
    setValue("pinc_share_premium", pincSharePremiumAmount.toFixed(2));
    setPincSharePremium(pincSharePremiumAmount);
  }, [watchPremiumAmount, watchPincShare]);

  useEffect(() => {
    var gstAmount = 0;
    if (watchNetAmount != null) {
      gstAmount = Number(watchNetAmount) * (18 / 100);
    }
    if (
      proposalPoolViewDetails?.closure?.gst_amount != null &&
      proposalPoolViewDetails?.closure?.gst_amount != 0
    ) {
      setValue("gst_amount", proposalPoolViewDetails?.closure?.gst_amount);
    } else {
      setValue("gst_amount", gstAmount.toFixed(2));
    }
  }, [watchNetAmount]);

  useEffect(() => {
    if (watchExpectedPremium != null) {
      const isNumeric = /^[0-9]*$/.test(watchExpectedPremium);
      if (isNumeric) {
        const numericValue = parseFloat(watchExpectedPremium || "0");
        const toWords = new ToWords();
        const words = toWords.convert(numericValue);
        setNumberInWords(words);
      }
    } else {
      if (proposalPoolViewDetails?.expected_premium) {
        const numericValue = parseFloat(
          proposalPoolViewDetails?.expected_premium || "0"
        );
        const toWords = new ToWords();
        const words = toWords.convert(numericValue);
        setNumberInWords(words);
      }
    }
  }, [watchExpectedPremium]);

  useEffect(() => {
    var totalPremium = 0;
    if (watchNetAmount != null && watchGstAmount != null) {
      totalPremium = Number(watchNetAmount) + Number(watchGstAmount);
    }
    setValue("total_premium", totalPremium.toFixed(2));
  }, [watchGstAmount, watchNetAmount]);

  useEffect(() => {
    if (questionList != null && questionList.length > 0) {
      // questionList.forEach((item, index) => {
      //   if (watchPremiumAmount != null && item?.share != null && item?.broker_id != 620 && closureType === "co_broking" ) {
      //     const sharePremiumAmount =
      //       (Number(watchPremiumAmount) * Number(item.share)) / 100;
      //     if (item.share_premium_amount !== sharePremiumAmount.toFixed(2)) {
      //       const tempMobileArray = [...questionList];
      //       const objMobile = { ...tempMobileArray[index] };
      //       objMobile.share_premium_amount = sharePremiumAmount.toFixed(2);
      //       tempMobileArray[index] = objMobile;
      //       setQuestionList(tempMobileArray);
      //     }
      //   }
      // });
      const totalShare = questionList.reduce((total, item) => {
        return total + (item.share ? Number(item.share) : 0);
      }, 0);
      setTotalSharePercent(totalShare);

      const grossPremiumValue = questionList.reduce((total, item) => {
        return total + (item.gross_premium ? Number(item.gross_premium) : 0);
      }, 0);
      setTotalGrossPremiumValue(grossPremiumValue);


    } else {
      setTotalSharePercent(0);
      setTotalSharePremiumValue(0);
      setTotalGrossPremiumValue(0);
    }
  }, [questionList, watchPremiumAmount]);

    const calculatePercentageAmount =(baseAmount,percentage)=>{
      return (!isNaN(percentage) ? (Number(baseAmount) * Number(percentage)) / 100 : 0).toFixed(2);
    }
    

  useEffect(() => {
    if (
      questionList != null &&
      questionList.length > 0 &&
      watchPremiumAmount != null &&
      watchCgstPercentage != null &&
      watchCgstPercentage !== "" &&
      watchSgstPercentage != null &&
      watchSgstPercentage !== "" 
      // &&(watchODPremium != null || watchTPPremium != null)
    ) {
      let updatedQuestionList = []; 
    if (questionList && questionList.length ) {
      updatedQuestionList = questionList.map((item, index) => {
      if (item != null && item.share != null) {

            const sharePremiumAmount = calculatePercentageAmount(watchNetPremium, item.share);
            const cgstAmount = calculatePercentageAmount(sharePremiumAmount, watchCgstPercentage);
            const sgstAmount = calculatePercentageAmount(sharePremiumAmount, watchSgstPercentage);
            const grossPremium = (Number(sharePremiumAmount) + Number(cgstAmount )+ Number(sgstAmount)).toFixed(2);

          // not NaN
          if (!isNaN(grossPremium) && item.gross_premium !== grossPremium) {
            const updatedItem = { ...item }; // Create a new object
            updatedItem.gross_premium = grossPremium;
            return updatedItem;
          }

         // Share OD Premium
        const share_od_premium_amount = calculatePercentageAmount(watchODPremium, item.share);
        item.share_od_premium_amount = share_od_premium_amount 

        // Share TP Premium
        item.share_tp_premium_amount = calculatePercentageAmount(watchTPPremium, item.share);
        // if( closureType === "co_insurer" || item?.broker_id == 620){
          const calculatedSharePremiumAmount = !isNaN( item.share_od_premium_amount) && !isNaN(item.share_tp_premium_amount) ? (Number(item.share_od_premium_amount) + Number(item.share_tp_premium_amount)).toFixed(2) : 0;
        // }
        item.share_premium_amount = calculatedSharePremiumAmount;

        // Calculate OD Brokerage Amount
        const calculatedOdBrokeragePercent = Number(item.od_brokerage_percent);  
        // const calculatedOdBrokerageAmount = calculatePercentageAmount(watchODPremium, calculatedOdBrokeragePercent)
        let calculatedOdBrokerageAmount = calculatePercentageAmount(item.share_od_premium_amount, calculatedOdBrokeragePercent)
        if(closureType === "Co-insurer & Co-broking") {
          calculatedOdBrokerageAmount = calculatePercentageAmount(calculatedOdBrokerageAmount, item.pinc_share) 
        } else if(closureType === "Co-insurer" && brokeragePaidBy === "Lead"){
          calculatedOdBrokerageAmount = calculatePercentageAmount(watchODPremium, calculatedOdBrokeragePercent);
        } else if(closureType === "Co-insurer" && brokeragePaidBy === "Follow"){
          calculatedOdBrokerageAmount = calculatePercentageAmount(share_od_premium_amount, calculatedOdBrokeragePercent);
        }
        
        item.od_brokerage_amount = calculatedOdBrokerageAmount;

        // Calculate TP Brokerage Amount
        const calculatedTpBrokeragePercent = Number(item.tp_brokerage_percent);  
        let calculatedTpBrokerageAmount = closureType === "Co-insurer & Co-broking" ? calculatePercentageAmount(calculatedSharePremiumAmount, calculatedTpBrokeragePercent) : calculatePercentageAmount(item.share_tp_premium_amount, calculatedTpBrokeragePercent);
        if(closureType === "Co-insurer & Co-broking" ) {
          calculatedTpBrokerageAmount = calculatePercentageAmount(calculatedTpBrokerageAmount, item.pinc_share)
        } else if(closureType === "Co-insurer" && brokeragePaidBy === "Lead"){
          calculatedTpBrokerageAmount = calculatePercentageAmount(watchTPPremium, calculatedTpBrokeragePercent);
        } else if(closureType === "Co-insurer" && brokeragePaidBy === "Follow"){
          calculatedTpBrokerageAmount = calculatePercentageAmount(calculatedSharePremiumAmount, calculatedTpBrokeragePercent);
        }
        
        item.tp_brokerage_amount = calculatedTpBrokerageAmount;

        let calculatedRewardAmount = 0;
        if (item.reward_on === "Brokerage") {
          const calculatedRewardPercent = Number(item.reward_percent);  
          calculatedRewardAmount = calculatePercentageAmount((Number(item.od_brokerage_amount || 0) + Number(item.tp_brokerage_amount || 0)), calculatedRewardPercent);
          item.reward_amount = calculatedRewardAmount
        }
        if (item.reward_on === "Premium") {
          const calculatedRewardPercent = Number(item.reward_percent);  
          calculatedRewardAmount = calculatePercentageAmount(share_od_premium_amount, calculatedRewardPercent);
          item.reward_amount = calculatedRewardAmount
        }

        const calculatedBonusPercent = Number(item.bonus_percent);  
        let calculatedBonusAmount =  calculatePercentageAmount(item.share_premium_amount, calculatedBonusPercent);
        if(closureType === "Co-insurer & Co-broking" ) {
          calculatedBonusAmount = calculatePercentageAmount(calculatedBonusAmount, item.pinc_share)
        } else if(closureType === "Co-insurer" && brokeragePaidBy === "Lead"){
          calculatedBonusAmount = calculatePercentageAmount(watchNetPremium, calculatedBonusPercent);
        } else if(closureType === "Co-insurer" && brokeragePaidBy === "Follow"){
          calculatedBonusAmount = calculatePercentageAmount(calculatedSharePremiumAmount, calculatedBonusPercent);
        }
        item.bonus_amount = calculatedBonusAmount;
        // Calculate Total Brokerage Amount
        const calculatedTotalBrokerageAmount = (Number(calculatedOdBrokerageAmount) + Number(calculatedTpBrokerageAmount) + Number(calculatedBonusAmount || 0) + Number(calculatedRewardAmount || 0)).toFixed(2);

        // Check if the calculated value is different from the current value
        if (!isNaN(calculatedTotalBrokerageAmount) && item.total_brokerage_amt !== calculatedTotalBrokerageAmount) {
          const updatedItem = { ...item };
          updatedItem.total_brokerage_amt = calculatedTotalBrokerageAmount;
          return updatedItem;
        }
        return item;
      }
      })
    }
    if (!isEqual(updatedQuestionList, questionList)) {
      setQuestionList(updatedQuestionList);
    }

    const SharePremiumAmount = questionList.reduce((total, item) => {
      return (
        total +
        (item.share_premium_amount ? Number(item.share_premium_amount) : 0)
      );
    }, 0);
    setTotalSharePremiumValue(SharePremiumAmount);

  } }, [questionList, watchPremiumAmount, watchTPPremium, watchODPremium, watchCgstPercentage, watchSgstPercentage, closureType]);
 
  // New Net Premium
  useEffect(() => {
    if (watchODPremium || watchTPPremium) {
      var netPrem = 0;
      netPrem = Number(watchODPremium) + Number(watchTPPremium);
      setNetPremium(Number(netPrem.toFixed(2)));
      !isNaN(netPrem)&&setValue('total_net_premium',netPrem.toFixed(2))
    }
  }, [watchODPremium, watchTPPremium]);

  // Share OD Premium
  useEffect(() => {
    if (watchODPremium && watchPincShare) {
      var ShareODPremium = 0;
      ShareODPremium =
      (Number(watchODPremium) * (Number(watchPincShare) / 100)).toFixed(2);
      setShareODPremium(ShareODPremium);
      !isNaN(ShareODPremium)&& setValue('share_od_premium', ShareODPremium)
    }
  }, [watchODPremium, watchPincShare]);

  // Share TP Premium
  useEffect(() => {
    if (watchTPPremium && watchPincShare) {
      var ShareTPPremium = 0;
      ShareTPPremium =
     ( Number(watchTPPremium) * (Number(watchPincShare) / 100)).toFixed(2);
      // setShareTPPremium(ShareTPPremium);
      !isNaN(ShareTPPremium)&&  setValue('share_tp_premium', ShareTPPremium)
    }
  }, [watchTPPremium, watchPincShare]);

  // Share Net Premium
  useEffect(() => {
    if (watchShareODPremium && watchShareTPPremium) {
      var netSharePrem = 0;
      netSharePrem = (Number(watchShareODPremium) + Number(watchShareTPPremium)).toFixed(2);
      setShareNetPremium(netSharePrem);
    }
  }, [watchShareTPPremium, watchShareODPremium]);
  // OD Brokerage Amount
  useEffect(() => {
    if ((watchODPremium || watchShareODPremium) && watchODBrokeragePercent) {
      var ODbrokerageAmount = 0;
        ODbrokerageAmount =
         (Number(watchODPremium) * (Number(watchODBrokeragePercent) / 100)).toFixed(2);
      !isNaN(ODbrokerageAmount)&& setValue("od_brokerage_amt", ODbrokerageAmount);
    }
  }, [watchODBrokeragePercent, watchODPremium, watchShareODPremium]);

  // TP Brokerage Amount
  useEffect(() => {
    if ((watchTPPremium || watchShareTPPremium) && watchTPBrokeragePercent) {
      var TPbrokerageAmount = 0;
        TPbrokerageAmount =
          (Number(watchTPPremium) * (Number(watchTPBrokeragePercent) / 100)).toFixed(2);
      !isNaN(TPbrokerageAmount)&&setValue("tp_brokerage_amt", TPbrokerageAmount);
    }
  }, [watchTPBrokeragePercent, watchTPPremium, watchShareTPPremium]);

    // Reward Amount
    useEffect(() => {
      if ((watchRewardPercentage || watchODBrokerage) && watchRewardOn === "Brokerage") {
        var RewardAmount = 0;
        RewardAmount =
           ((Number(watchODBrokerage) + Number(watchTPBrokerage)) * (Number(watchRewardPercentage || 0) / 100)).toFixed(2);
        !isNaN(RewardAmount) && setValue("reward_amt", RewardAmount);
      }
      if ((watchRewardPercentage || watchODPremium) && watchRewardOn === "Premium") {
        var RewardAmount = 0;
        RewardAmount =
           ((Number(watchODPremium) + Number(watchTPPremium)) * (Number(watchRewardPercentage || 0) / 100)).toFixed(2);
        !isNaN(RewardAmount) && setValue("reward_amt", RewardAmount);
      }
    }, [watchRewardAmount, watchRewardPercentage, watchODBrokerage, watchTPBrokerage, watchODPremium, watchRewardOn]);

      // Bonus Amount
  useEffect(() => {
    // if ((watchNetPremium) && watchBonusPercentage) {
      var BonusAmount = 0;
      BonusAmount =
          (Number(watchNetPremium || 0) * (Number(watchBonusPercentage || 0) / 100)).toFixed(2);
      !isNaN(BonusAmount)&&setValue("bonus_amt", BonusAmount);
    // }
  }, [watchBonusPercentage, watchNetPremium]);

  // Total Brokerage
  useEffect(() => {
    if (watchODBrokerage || watchTPBrokerage) {
      var TotalBrokerageAmount = 0;
      TotalBrokerageAmount =
        (Number(watchODBrokerage) + Number(watchTPBrokerage) + Number(watchRewardAmount || 0) + Number(watchBonusAmount || 0)).toFixed(2);
        !isNaN(TotalBrokerageAmount)&& setValue("total_brokerage_amt", TotalBrokerageAmount);
    } else {
      setValue("total_brokerage_amt", 0);
    }
  }, [watchODBrokerage, watchTPBrokerage, watchRewardAmount, watchBonusAmount]);

  const onProposalPoolViewUpdate = () => {
    const { ProposalPoolView } = props;
    var ProposalPoolViewResponse = Object.assign({}, ProposalPoolView);
    if (ProposalPoolViewResponse.result === true) {
      QuoteStatusDropdown(
        ProposalPoolViewResponse.response?.opportunity_status
      );
      setValue("opportunity_id", ProposalPoolViewResponse.response?.detail?.id);
      setValue(
        "opportunity_type",
        ProposalPoolViewResponse.response?.detail?.oppurtunity_type
      );
      setOpportunityType(
        ProposalPoolViewResponse.response?.detail?.oppurtunity_type
      );
      setValue(
        "policy_type_id",
        ProposalPoolViewResponse.response?.detail?.policy_type_id
      );
      setValue(
        "department_name",
        ProposalPoolViewResponse.response?.detail?.department_id
      );
      setDepartmentId(ProposalPoolViewResponse.response?.detail?.department_id);
      setValue(
        "intermediary_type",
        ProposalPoolViewResponse.response?.detail?.intermediary_type
      );
      setIntermediaryType(
        ProposalPoolViewResponse.response?.detail?.intermediary_type
      );
      setValue(
        "intermediary_value",
        ProposalPoolViewResponse.response?.detail.intermediary_value
      );
      setValue(
        "intermediary_value",
        ProposalPoolViewResponse.response?.detail?.intermediary_value
      );
      setValue(
        "insurer_id",
        ProposalPoolViewResponse.response?.detail?.insurer_id
      );
      setValue(
        "expected_premium",
        ProposalPoolViewResponse.response?.detail.expected_premium
      );
      
      setValue(
        "expiring_date",
        ProposalPoolViewResponse.response?.detail?.lead_expiry_date !=
          "0000-00-00 00:00:00"
          ? ProposalPoolViewResponse.response?.detail?.lead_expiry_date
          : ""
      );
      // setExpiringDate(
      //   dateFormat(
      //     ProposalPoolViewResponse.response?.detail?.lead_expiry_date,
      //     "yyyy-mm-dd"
      //   )
      // );
      setOpportunityId(ProposalPoolViewResponse.response?.detail?.id);
      setValue(
        "probability",
        ProposalPoolViewResponse.response?.detail?.probability != null
          ? ProposalPoolViewResponse.response?.detail?.probability
          : ""
      );
      setProbability(
        ProposalPoolViewResponse.response?.detail?.probability != null
          ? ProposalPoolViewResponse.response?.detail?.probability
          : ""
      );
    
      setValue(
        "client_remark",
        ProposalPoolViewResponse.response?.detail?.client_remark
      );
      setValue(
        "opportunity_remark",
        ProposalPoolViewResponse.response?.detail?.opportunity_remark
      );
      setOpportunityInsurerId(
        ProposalPoolViewResponse.response?.detail?.closure?.insurer_id
      );
      setPlacementId(
        ProposalPoolViewResponse.response?.detail?.closure?.placement_id
      );
      // COINSURERAPI
      setPolicyDetails(
        ProposalPoolViewResponse.response?.detail.policy_details
      );
      if (
        ProposalPoolViewResponse.response?.detail?.opportunity_insurers
          ?.length > 0 &&
        ProposalPoolViewResponse.response?.detail?.opportunity_status === "Open"
      ) {
        setValue("opportunity_status", "Quote Under Process");
      } else {
        setValue(
          "opportunity_status",
          ProposalPoolViewResponse.response?.detail?.opportunity_status
        );
      }
      setQuoteStatus(
        ProposalPoolViewResponse.response?.detail?.opportunity_status
      );
      setValue(
        "opportunity_sub_status",
        ProposalPoolViewResponse.response?.detail?.opportunity_sub_status !=
          "Open"
          ? ProposalPoolViewResponse.response?.detail?.opportunity_sub_status
          : ""
      );
      const proposalClosureType = ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type != null &&
      ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type != "null"
      ? ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.closure_type
      : "";
      setValue("closure_type", proposalClosureType);
      setClosureType(proposalClosureType);
      const opportunitySuccessList = ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.opportunity_success_list;

      //  (closureType === "Co-broking" || closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking")
      if(proposalClosureType === "Co-broking" || proposalClosureType === "Co-insurer" || proposalClosureType === "Co-insurer & Co-broking") {
        const insurerList = opportunitySuccessList?.length > 0 ? opportunitySuccessList.map((item) => ({
          id: item.id,
          broker_id: item.entity_type === "BROKER" ? item.entity_id: "",
          insurer_id: item.entity_type === "INSURER" ? item.entity_id: "",
          sgst: item.sgst,
          cgst: item.cgst,
          gross_premium: item.gross_premium,
          share_od_premium_amount: item.od_premium,
          share_tp_premium_amount: item.tp_premium || 0,
          share_premium_amount: item.net_premium,
          od_brokerage_percent: item.od_brokerage_perc ,
          od_brokerage_amount: item.od_brokerage_amount ,
          tp_brokerage_percent: item.tp_brokerage_perc ,
          tp_brokerage_amount: item.tp_brokerage_amount ,
          reward_on: item.reward_on,
          reward_percent: item.reward_perc ,
          reward_amount: item.reward_amount,
          bonus_percent: item.bonus_reward_perc ,
          bonus_amount: item.bonus_reward_amount ,
          share: item.share,
          pinc_share: item.pinc_share,
          premium: "",
          type: item.type ,
          entity_type: item.entity_type,
        })) : [];
        setQuestionList(insurerList);
      } else if(proposalClosureType === "Standard") {
        if(opportunitySuccessList?.length > 0) {
          const item = opportunitySuccessList[0];
          setValue("od_brokerage_percentage", item.od_brokerage_perc || "")
          setValue("od_brokerage_amt", item.od_brokerage_amount || "")
          setValue("tp_brokerage_percentage", item.tp_brokerage_perc || "")
          setValue("tp_brokerage_amt", item.tp_brokerage_amount || "")
          setValue("reward_on", item.reward_on || "")
          setValue("reward_percentage", item.reward_perc || "")
          setValue("reward_amt", item.reward_amount || "")
          setValue("bonus_percentage", item.bonus_reward_perc || "")
          setValue("bonus_amt", item.bonus_reward_amount || "")
          setValue("total_brokerage_amt", item.total_brokerage_amount || "")
        }
      }

      setValue(
        "premium_amount",
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.premium_amount
      );
      setValue(
        "tp_net_premium",
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.tp_net_premium
      );
      setPremium(
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.premium_amount
      );
      if(ProposalPoolViewResponse?.response?.detail?.opportunity_success_details?.opportunity_success_list?.length > 0) {
        setValue(
          "cgst",
          ProposalPoolViewResponse?.response?.detail?.opportunity_success_details?.opportunity_success_list[0]?.cgst
        );
        setValue(
          "sgst",
          ProposalPoolViewResponse?.response?.detail?.opportunity_success_details?.opportunity_success_list[0]?.sgst
        );
      }
      
      // setValue(
      //   "instalment",
      //   ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.instalment ? Number(ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.instalment).toFixed(0) : ""
      // );
      setValue(
        "business_closure_date",
        moment(
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details
            ?.business_closure_date_formated
        ).format("yyyy-MM-DD")
      );
      setClosureDate(
        moment(
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details
            ?.business_closure_date_formated
        ).format("yyyy-MM-DD")
      );
      setPolicyDate(
        moment(
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details
            ?.policy_start_date_formated
        ).format("yyyy-MM-DD")
      );
      setPolicyEndDate(
        ProposalPoolViewResponse.response?.detail?.end_date != null &&
          ProposalPoolViewResponse.response?.detail?.end_date != ""
          ? moment(ProposalPoolViewResponse.response?.detail?.end_date).format(
            "yyyy-MM-DD"
          )
          : moment(new Date()).add(1, "year").format("yyyy-MM-DD")
      );
      setValue(
        "policy_start_date",
        moment(
          ProposalPoolViewResponse.response?.detail?.opportunity_success_details
            ?.policy_start_date_formated
        ).format("yyyy-MM-DD")
      );
      setValue(
        "end_date",
        ProposalPoolViewResponse.response?.detail?.end_date !=
          "0000-00-00 00:00:00" &&
          ProposalPoolViewResponse.response?.detail?.end_date != null
          ? ProposalPoolViewResponse.response?.detail?.end_date
          : moment(new Date()).add(1, "year").format("yyyy-MM-DD")
      );
      setValue(
        "opportunity_intermediary_type",
        ProposalPoolViewResponse.response?.detail?.intermediary_type
      );
      setCompetitionIntermediaryType(
        ProposalPoolViewResponse.response?.detail?.intermediary_type
      );
      setValue(
        "broking_insurer_id",
        ProposalPoolViewResponse.response?.detail?.closure?.insurer_id
      );
      setValue(
        "intermediary_name",
        ProposalPoolViewResponse.response?.detail?.closure?.intermediary_name
      );
      setValue(
        "intermediary_name",
        ProposalPoolViewResponse.response?.detail?.closure?.intermediary_name
      );
      setProposalLostReason(
        ProposalPoolViewResponse.response?.detail?.closure?.intermediary_name
      );
      setValue(
        "net_amount",
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.net_amount
      );
      setValue(
        "pinc_share",
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.pinc_share
      );
      const insurerIds =
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.opportunity_co_insurer_success_detail_list?.map(
          (item) => item.insurer_or_broker_id
        );
      setValue("insurer_ids", insurerIds ? insurerIds : []);
      const brokerIds =
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.opportunity_co_broker_success_detail_list?.map(
          (item) => item.insurer_or_broker_id
        );
      setValue("broker_ids", brokerIds ? brokerIds : []);
      // if (
      //   ProposalPoolViewResponse.response?.detail?.opportunity_status ===
      //   "Proposal Success"
      // ) {
      //   if (
      //     ProposalPoolViewResponse.response?.detail?.closure_type ===
      //     "co_broking"
      //   ) {
      //     setQuestionList(
      //       ProposalPoolViewResponse.response?.detail?.closure
      //         ?.opportunity_broker_success_detail_list
      //     );
      //   } else if (
      //     ProposalPoolViewResponse.response?.detail?.closure_type ===
      //     "co_insurer"
      //   ) {
      //     setQuestionList(
      //       ProposalPoolViewResponse.response?.detail?.closure
      //         ?.opportunity_insurer_success_detail_list
      //     );
      //   }
      // }
      setValue(
        "co_insurer_n_co_broking_reason",
        ProposalPoolViewResponse.response?.detail
          ?.co_insurer_n_co_broking_reason
      );
      setValue(
        "gst_amount",
        ProposalPoolViewResponse.response?.detail?.opportunity_success_details?.gst_amount
      );
      setValue(
        "sales_remark",
        ProposalPoolViewResponse.response?.detail?.closure?.sales_remark !=
          "null"
          ? ProposalPoolViewResponse.response?.detail?.closure?.sales_remark
          : ""
      );
      setValue(
        "payment_sent_to_insurer",
        ProposalPoolViewResponse.response?.detail?.closure
          ?.payment_sent_to_insurer
      );
      setValue(
        "client_response",
        ProposalPoolViewResponse.response?.detail?.closure?.client_response
      );
      // setValue(
      //   "opportunity_insurer_id",
      //   ProposalPoolViewResponse.response?.detail?.opportunity_status ===
      //     "Proposal Success"
      //     ? ProposalPoolViewResponse.response?.detail?.closure?.placement_id
      //     : ProposalPoolViewResponse.response?.detail?.closure?.insurer_id
      // );
      setValue("reward_on", "Brokerage");
      setValue("brokerage_paid_by", "Lead");
      setBrokeragePaidBy("Lead");
      setProposalPoolViewDetails(ProposalPoolViewResponse.response?.detail);
      setProposalProductRight(ProposalPoolViewResponse.response?.ps_access);
      $("#progress-loader").addClass("hidden");
    } else {
      switch (ProposalPoolViewResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalPoolViewResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalPoolViewUpdate, [props.ProposalPoolView]);

  const handleBrokeragePercentage = (event) => {
    const enteredChar = event.key;
    const inputValue = event.target.value + enteredChar;

    // Allow decimal point and digits, and values in the range of 0 to 100
    if (
      !/^\d*\.?\d*$/.test(enteredChar) ||
      parseFloat(inputValue) < 0 ||
      parseFloat(inputValue) > 100
    ) {
      event.preventDefault();
    }
  };

  const onMasterProbabilityGroupUpdate = () => {
    const { ProbabilityGroup } = props;
    var ProbabilityGroupResponse = Object.assign({}, ProbabilityGroup);
    if (ProbabilityGroupResponse.result === true) {
      setProbabilityGroup(ProbabilityGroupResponse.response);
    } else {
      switch (ProbabilityGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProbabilityGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterProbabilityGroupUpdate, [props.ProbabilityGroup]);

  useEffect(() => {
    if (probabilityGroup != null && probabilityGroup.length > 0) {
      if (proposalPoolViewDetails !== null) {
        setValue(
          "probability",
          proposalPoolViewDetails?.probability != null
            ? proposalPoolViewDetails?.probability
            : ""
        );
        props.actionOpportunityQuestionsList({});
      }
    }
  }, [probabilityGroup]);

  const onDeleteDocumentUpdate = () => {
    const { DeleteDocument } = props;
    var DeleteDocumentResponse = Object.assign({}, DeleteDocument);
    if (DeleteDocumentResponse.result === true) {
      props.actionProposalPoolView({
        policy_id: detailsData.id,
        opportunity_status: detailsData.opportunity_status,
        closure_type: detailsData.closure_type,
      });
      props.actionProposalGetPlacements({ policy_id: detailsData.id });
    } else {
      switch (DeleteDocumentResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(DeleteDocumentResponse.message);
        default:
      }
    }
  };
  useEffect(onDeleteDocumentUpdate, [props.DeleteDocument]);

  const onOpportunityQuestionListUpdate = () => {
    const { OpportunityQuestionList } = props;
    var OpportunityQuestionListResponse = Object.assign(
      {},
      OpportunityQuestionList
    );
    if (OpportunityQuestionListResponse.result === true) {
      var opportunityQuestionArr = OpportunityQuestionListResponse.response.map(
        (item) => ({
          ...item,
          question_value: "",
        })
      );
      setOpportunityQuestionArrayList(OpportunityQuestionListResponse.response);
      setValue("opportunityQuestion", opportunityQuestionArr);
      setArrSystenProbability(opportunityQuestionArr);
      if (
        detailsData?.id != null &&
        detailsData?.id != "" &&
        proposalPoolViewDetails?.probability >= 50
      ) {
        props.actionGetOpportunityQuestions({ id: detailsData?.id });
      }
    } else {
      switch (OpportunityQuestionListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onOpportunityQuestionListUpdate, [props.OpportunityQuestionList]);

  useEffect(() => {
    if (
      opportunityQuestionArrayList != null &&
      opportunityQuestionArrayList.length > 0
    ) {
      if (opportunityQuestionArrayList != null) {
        var opportunityQuestionArr = opportunityQuestionArrayList.map(
          (item) => ({
            ...item,
            question_value: "",
          })
        );
        setValue("opportunityQuestion", opportunityQuestionArr);
      }
    }
  }, [opportunityQuestionArrayList]);

  useEffect(() => {
    if (departmentId != null) {
      props.actionGetAssignProductRM({
        department_id: departmentId,
        assigned_rm_id: detailsData?.assigned_rm_id,
      });
      props.actionDepartmentInsuranceCompanyGroup({
        department_id: departmentId,
        status: "ACTIVE",
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if (getAssignProductRM != null && getAssignProductRM.length > 0) {
      if (proposalPoolViewDetails) {
        if (
          proposalPoolViewDetails?.policy_type_id != getValues("policy_type_id")
        ) {
          setValue("product_specialist_id", 0);
        } else if (
          proposalPoolViewDetails?.opportunity_status === "Open" &&
          proposalPoolViewDetails?.product_specialist_id === null
        ) {
          setValue("product_specialist_id", 0);
        } else {
          setValue(
            "product_specialist_id",
            proposalPoolViewDetails?.product_specialist_id
          );
        }
      }
    }
  }, [getAssignProductRM]);

  const onAssignProductRMUpdate = () => {
    const { GetAssignProductRM } = props;
    var GetAssignProductRMResponse = Object.assign({}, GetAssignProductRM);
    if (GetAssignProductRMResponse.result === true) {
      var arrAssignRM = Object.assign([], GetAssignProductRMResponse.response);
      if (proposalPoolViewDetails.opportunity_status === "Open") {
        var user = {
          user_id: 0,
          full_name: "None",
        };

        arrAssignRM.unshift(user);
      }
      setGetAssignProductRM(arrAssignRM);
    } else {
      switch (GetAssignProductRMResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          setGetAssignProductRM([{ user_id: 0, full_name: "None" }]);
        default:
      }
    }
  };
  useEffect(onAssignProductRMUpdate, [props.GetAssignProductRM]);

  const onGetOpportunityQuestionUpdate = () => {
    const { GetOpportunityQuestion } = props;
    var GetOpportunityQuestionResponse = Object.assign(
      {},
      GetOpportunityQuestion
    );
    if (GetOpportunityQuestionResponse.result === true) {
      var arrData = [];
      if (
        GetOpportunityQuestionResponse.response.opportunity_questions != null &&
        GetOpportunityQuestionResponse.response.opportunity_questions.length >
        0 &&
        arrSystemProbability != null &&
        arrSystemProbability.length > 0
      ) {
        arrSystemProbability.forEach((element) => {
          var value =
            GetOpportunityQuestionResponse.response.opportunity_questions.find(
              (item) => item.id === element.id
            );
          var questionValue = "";
          if (value != null && value != undefined) {
            questionValue = value.question_value;
            setIsOpportunityQuestion(false);
          } else {
            setIsOpportunityQuestion(true);
          }
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: questionValue,
          });
        });
        setValue("opportunityQuestion", arrData);
        setArrSystenProbability(arrData);
      }
    } else {
      switch (GetOpportunityQuestionResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onGetOpportunityQuestionUpdate, [props.GetOpportunityQuestion]);

  const onInstallmentUpdate = () => {
    const { Installment } = props;
    var InstallmentResponse = Object.assign({}, Installment);
    if (InstallmentResponse.result === true) {
      setInstallment(InstallmentResponse?.response.opportunity_instalments);
    } else {
      switch (InstallmentResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(InstallmentResponse.message);
        default:
      }
    }
  };
  useEffect(onInstallmentUpdate, [props.Installment]);

  useEffect(() => {
    if (installment != null && installment.length > 0) {
      if (proposalPoolViewDetails) {
        setValue(
          "instalment",
          proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_success_details?.instalment
            ? parseFloat(proposalPoolViewDetails?.opportunity_success_details?.instalment) === 0 ? "No Instalment" : parseFloat(proposalPoolViewDetails?.opportunity_success_details?.instalment)?.toString()
            : "No Instalment"
        );
      }
    }
  }, [installment, proposalPoolViewDetails]);

 

  const onMasterPolicyGroupUpdate = () => {
    const { PolicyGroup } = props;
    var PolicyGroupResponse = Object.assign({}, PolicyGroup);
    if (PolicyGroupResponse.result === true) {
      setPolicyGroup(PolicyGroupResponse.response);
    } else {
      switch (PolicyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(PolicyGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterPolicyGroupUpdate, [props.PolicyGroup]);

  useEffect(() => {
    if (policyGroup != null && policyGroup.length > 0) {
      if (proposalPoolViewDetails !== null) {
        setValue(
          "policy_type_id",
          proposalPoolViewDetails != null
            ? proposalPoolViewDetails.policy_type_id
            : ""
        );
      }
    }
  }, [policyGroup]);

  const onIntermediaryTypeUpdate = () => {
    const { IntermediaryType } = props;
    var IntermediaryTypeResponse = Object.assign({}, IntermediaryType);
    if (IntermediaryTypeResponse.result === true) {
      SetIntermediaryTypeList(
        IntermediaryTypeResponse.response.opportunity_intermediary_type
      );
    } else {
      switch (IntermediaryTypeResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(IntermediaryTypeResponse.message);
        default:
      }
    }
  };
  useEffect(onIntermediaryTypeUpdate, [props.IntermediaryType]);

  const onGetBrokerNameUpdate = () => {
    const { GetBrokerName } = props;
    var GetBrokerNameResponse = Object.assign({}, GetBrokerName);
    if (GetBrokerNameResponse.result === true) {
      setGetBrokerName(GetBrokerNameResponse.response);
    } else {
      switch (GetBrokerNameResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(GetBrokerNameResponse.message);
        default:
      }
    }
  };
  useEffect(onGetBrokerNameUpdate, [props.GetBrokerName]);

  const onMasterTagUserUpdate = () => {
    const { TagUserList } = props;
    var TagUserResponse = Object.assign({}, TagUserList);
    if (TagUserResponse.result === true) {
      console.log(TagUserResponse, "sgdhgsds")
      setTagUserList(TagUserResponse.response);
    } else {
      switch (TagUserResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(TagUserResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterTagUserUpdate, [props.TagUserList]);

  useEffect(() => {
    if (getBrokerName != null && getBrokerName.length > 0) {
      if (proposalPoolViewDetails !== null) {
        setValue(
          "intermediary_value",
          proposalPoolViewDetails !== null
            ? proposalPoolViewDetails?.intermediary_value
            : ""
        );
        setValue(
          "intermediary_name",
          proposalPoolViewDetails !== null
            ? proposalPoolViewDetails?.closure?.intermediary_name
            : ""
        );
      }
    }
  }, [getBrokerName]);

  useEffect(() => {
    if (
      userId == 1 ||
      // userId === proposalPoolViewDetails?.product_specialist_id ||
      proposalProductRight === true ||
      userId === proposalPoolViewDetails?.user_id ||
      userId === proposalPoolViewDetails?.assigned_rm_id
    ) {
      setEnabledValue(true);
    } else {
      setEnabledValue(false);
    }
  }, [proposalPoolViewDetails]);

  const onClosureTypeUpdate = () => {
    const { ClosureType } = props;
    var ClosureTypeResponse = Object.assign({}, ClosureType);
    if (ClosureTypeResponse.result === true) {
      setClosureTypeList(ClosureTypeResponse.response.opportunity_closure_type);
    } else {
      switch (ClosureTypeResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ClosureTypeResponse.message);
        default:
      }
    }
  };
  useEffect(onClosureTypeUpdate, [props.ClosureType]);

  useEffect(() => {
    if (closureTypeList != null && closureTypeList.length > 0) {
      if (proposalPoolViewDetails !== null) {
        setValue(
          "closure_type",
          proposalPoolViewDetails != null &&
            proposalPoolViewDetails.closure_type != "null"
            ? proposalPoolViewDetails.closure_type
            : ""
        );
      }
    }
  }, [closureTypeList]);

  useEffect(() => {
    if (getTagUser != null && getTagUser.length > 0) {
      if (proposalPoolViewDetails && proposalPoolViewDetails.tag_users) {
        setValue(
          "tag_user_id",
          proposalPoolViewDetails.tag_users !== "null"
            ? proposalPoolViewDetails.tag_users.map((item) => item.tag_user_id)
            : ""
        );
      }
    }
  }, [getTagUser, proposalPoolViewDetails]);
  
  

  const onDepartmentWiseInsurerUpdate = () => {
    const { DepartmentWiseInsurer } = props;
    var DepartmentWiseInsurerResponse = Object.assign(
      {},
      DepartmentWiseInsurer
    );
    if (DepartmentWiseInsurerResponse.result === true) {
      setDepartmentWiseInsurer(DepartmentWiseInsurerResponse.response);
      setValue(
        "insurer_id",
        proposalPoolViewDetails != null
          ? proposalPoolViewDetails?.insurer_id
          : ""
      );
      // setValue(
      //   "opportunity_insurer_id",
      //   proposalPoolViewDetails !== null
      //     ? proposalPoolViewDetails?.opportunity_success_details?.insurer_id
      //     : ""
      // );
      // setValue(
      //   "opportunity_insurer_id",
      //   proposalPoolViewDetails !== null
      //     ? proposalPoolViewDetails?.opportunity_success_details?.placement_id
      //     : ""
      // );
    } else {
      switch (DepartmentWiseInsurerResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(DepartmentWiseInsurerResponse.message);
        default:
      }
    }
  };
  useEffect(onDepartmentWiseInsurerUpdate, [props.DepartmentWiseInsurer]);

  const onMasterInsuranceCompanyGroupUpdate = () => {
    const { InsuranceCompanyGroup } = props;
    var InsuranceCompanyGroupResponse = Object.assign(
      {},
      InsuranceCompanyGroup
    );
    if (InsuranceCompanyGroupResponse.result === true) {
      setInsuranceCompanyGroup(InsuranceCompanyGroupResponse.response);
    } else {
      switch (InsuranceCompanyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(InsuranceCompanyGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterInsuranceCompanyGroupUpdate, [props.InsuranceCompanyGroup]);

  useEffect(() => {
    if (insuranceCompanyGroup != null && insuranceCompanyGroup.length > 0) {
      if (
        proposalPoolViewDetails !== null &&
        proposalPoolViewDetails?.opportunity_success_details?.placement_id != null
      ) {
        setValue(
          "broking_insurer_id",
          proposalPoolViewDetails?.opportunity_success_details?.placement_id
        );
      }
    }
  }, [insuranceCompanyGroup]);

  const onProposalPlacementGetUpdate = () => {
    const { ProposalPlacementGet } = props;
    var ProposalPlacementGetResponse = Object.assign({}, ProposalPlacementGet);
    if (ProposalPlacementGetResponse.result === true) {
      SetGetProposalPlacement(ProposalPlacementGetResponse.response);
      if (
        ProposalPlacementGetResponse?.response?.length != null &&
        ProposalPlacementGetResponse?.response?.length > 0 &&
        detailsData?.opportunity_status === "Open"
      ) {
        setValue("opportunity_status", "Quote Under Process");
      }
      props.actionInsuranceListForSuccess({
        policy_id: detailsData.id,
        type: "ACTIVE",
      });
    } else {
      switch (ProposalPlacementGetResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalPlacementGetResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalPlacementGetUpdate, [props.ProposalPlacementGet]);

  const onInsuranceSuccessListUpdate = () => {
    const { InsuranceSuccessList } = props;
    var InsuranceSuccessListResponse = Object.assign({}, InsuranceSuccessList);
    if (InsuranceSuccessListResponse.result === true) {
      SetGetInsuranceSuccessList(InsuranceSuccessListResponse.response);
    } else {
      switch (InsuranceSuccessListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(InsuranceSuccessListResponse.message);
        default:
      }
    }
  };
  useEffect(onInsuranceSuccessListUpdate, [props.InsuranceSuccessList]);

  useEffect(() => {
    if (getInsuranceSuccessList != null && getInsuranceSuccessList.length > 0) {
      if (
        proposalPoolViewDetails !== null &&
        proposalPoolViewDetails?.opportunity_status === "Proposal Success"
      ) {
        setValue(
          "opportunity_insurer_id",
          proposalPoolViewDetails !== null && proposalPoolViewDetails?.opportunity_success_details && proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list?.length > 0
            ? proposalPoolViewDetails?.opportunity_success_details?.opportunity_success_list[0]?.entity_id
            : ""
        );
      }
    }
  }, [getInsuranceSuccessList]);

  const onProposalQuoteStatusUpdate = () => {
    const { ProposalQuoteStatus } = props;
    var ProposalQuoteStatusResponse = Object.assign({}, ProposalQuoteStatus);
    if (ProposalQuoteStatusResponse.result === true) {
    } else {
      switch (ProposalQuoteStatusResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalQuoteStatusResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalQuoteStatusUpdate, [props.ProposalQuoteStatus]);

  useEffect(() => {
    if (quoteStatusDropdown != null && quoteStatusDropdown.length > 0) {
      if (detailsData !== null) {
        if (
          getProposalPlacement?.length > 0 &&
          detailsData?.opportunity_status === "Open"
        ) {
          setValue("opportunity_status", "Quote Under Process");
        } else {
          setValue(
            "opportunity_status",
            detailsData !== null ? detailsData?.opportunity_status : ""
          );
        }
      }
    }
  }, [quoteStatusDropdown]);

  const onProposalQuoteRejectStatusUpdate = () => {
    const { ProposalQuoteRejectStatus } = props;
    var ProposalQuoteRejectStatusResponse = Object.assign(
      {},
      ProposalQuoteRejectStatus
    );
    if (ProposalQuoteRejectStatusResponse.result === true) {
      QuoteRejectStatusDropdown(
        ProposalQuoteRejectStatusResponse.response
          .opportunity_quote_reject_status
      );
      setValue(
        "opportunity_sub_status",
        proposalPoolViewDetails !== null &&
          proposalPoolViewDetails?.opportunity_sub_status != "Open"
          ? proposalPoolViewDetails?.opportunity_sub_status
          : ""
      );
    } else {
      switch (ProposalQuoteRejectStatusResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalQuoteRejectStatusResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalQuoteRejectStatusUpdate, [
    props.ProposalQuoteRejectStatus,
  ]);

  useEffect(() => {
    if (quoteStatusDropdown != null && quoteStatusDropdown.length > 0) {
      if (proposalPoolViewDetails !== null) {
        setValue(
          "opportunity_sub_status",
          proposalPoolViewDetails !== null &&
            proposalPoolViewDetails?.opportunity_sub_status != "Open"
            ? proposalPoolViewDetails?.opportunity_sub_status
            : ""
        );
      }
    }
  }, [quoteStatusDropdown]);

  const onProposalLostStatusUpdate = () => {
    const { ProposalLostStatus } = props;
    var ProposalLostStatusResponse = Object.assign({}, ProposalLostStatus);
    if (ProposalLostStatusResponse.result === true) {
      SetLostStatusDropdown(
        ProposalLostStatusResponse.response.opportunity_proposal_status
      );
    } else {
      switch (ProposalLostStatusResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalLostStatusResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalLostStatusUpdate, [props.ProposalLostStatus]);

  const onProposalPoolEditOpportunityUpdate = () => {
    const { ProposalPoolEditOpportunity } = props;
    var ProposalPoolEditOpportunityResponse = Object.assign(
      {},
      ProposalPoolEditOpportunity
    );
    $("#editproposal_pool").prop("disabled", false);
    if (ProposalPoolEditOpportunityResponse.result === true) {
      $("#proposalpool-edit-loader").addClass("hidden");
      if (showAddPlacement != true) {
        window.close();
      }
    } else {
      $("#proposalpool-edit-loader").addClass("hidden");
      switch (ProposalPoolEditOpportunityResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalPoolEditOpportunityResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalPoolEditOpportunityUpdate, [
    props.ProposalPoolEditOpportunity,
  ]);

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="Action" type="buttons">
            {/* {
                            userId == 1 ||
                                (userId != proposalPoolViewDetails?.assigned_rm_id &&
                                    userId === proposalPoolViewDetails?.product_specialist_id) ?
                                <Button
                                    render={(params) => (
                                        <button
                                            type='button'
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                setShowAddPlacement(true);
                                                setPlacementData(params.data)
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        } */}
            {!(
              userId === proposalPoolViewDetails?.assigned_rm_id &&
              // userId !== proposalPoolViewDetails?.product_specialist_id
              proposalProductRight !== true
            ) || userId === 1 ? (
              <Button
                render={(params) => (
                  <button
                    type="button"
                    className="btn btn-icon btn-sm"
                    onClick={() => {
                      setShowAddPlacement(true);
                      setPlacementData(params.data);
                    }}
                  >
                    <FiEdit size={16} className="primary" />
                  </button>
                )}
              />
            ) : (
              <></>
            )}
          </Column>
        );
      } else {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
      }
    });
    return arrColumns;
  };

  const onSubmit = (data,ProposalPoolViewDetails) => {
    var arrData = [];
    data.opportunityQuestion.forEach((element) => {
      if (element.question_value === undefined) {
        var value = arrSystemProbability.find((item) => item.id === element.id);
        var questionValue = "";
        if (value != null && value != undefined) {
          //questionValue = value.question_value
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: value.question_value,
            yes_value: element.yes_value,
            no_value: element.no_value,
          });
        } else {
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: element.question_value,
            yes_value: element.yes_value,
            no_value: element.no_value,
          });
        }
      } else {
        arrData.push({
          id: element.id,
          question: element.question,
          question_value: element.question_value,
          yes_value: element.yes_value,
          no_value: element.no_value,
        });
      }
    });

    const arrQuestion = closureType === "Standard" ? [] : questionList.map((item) => {
      const {
        id,
        entity_type,
        insurer_id,
        broker_id,
        type,
        pinc_share,
        share,
        share_od_premium_amount,
        share_tp_premium_amount,
        share_premium_amount,
        od_brokerage_percent,
        od_brokerage_amount,
        tp_brokerage_percent,
        tp_brokerage_amount,
        reward_on,
        reward_percent,
        reward_amount,
        bonus_percent,
        bonus_amount,
        gross_premium,
        total_brokerage_amt
      } = item;
      return {
        id,
        entity_id: entity_type === "BROKER" ? broker_id : insurer_id,
        entity_type,
        type: (type === "lead" || type === "Lead") ? "Lead" : "Follow",
        cgst: data.cgst != undefined && data.cgst != null ? data.cgst : "",
        sgst: data.sgst != undefined && data.sgst != null ? data.sgst : "",
        pinc_share,
        share,
        od_premium: share_od_premium_amount || 0,
        tp_premium: share_tp_premium_amount || 0,
        net_premium: share_premium_amount,
        od_brokerage_perc: od_brokerage_percent,
        od_brokerage_amount,
        tp_brokerage_perc: tp_brokerage_percent,
        tp_brokerage_amount,
        reward_on,
        reward_perc: reward_percent,
        reward_amount,
        bonus_reward_perc: bonus_percent,
        bonus_reward_amount: bonus_amount,
        gross_premium,
        total_brokerage_amount: total_brokerage_amt || 0,
      };
    });

    if(closureType === "Standard") {
      const standardBrokerageDetails = {
        entity_id: data.opportunity_insurer_id,
        entity_type: "INSURER",
        type: "Lead",
        cgst:  data.cgst != undefined && data.cgst != null ? data.cgst : "",
        sgst:  data.sgst != undefined && data.sgst != null ? data.sgst : "",
        net_premium:  0,
        od_brokerage_perc:  data.od_brokerage_percentage != undefined && data.od_brokerage_percentage != null ? data.od_brokerage_percentage : "",
        od_brokerage_amount: data.od_brokerage_amt != undefined && data.od_brokerage_amt != null ? data.od_brokerage_amt : "",
        tp_brokerage_perc:  data.tp_brokerage_percentage != undefined && data.tp_brokerage_percentage != null ? data.tp_brokerage_percentage : "",
        tp_brokerage_amount:  data.tp_brokerage_amt != undefined && data.tp_brokerage_amt != null ? data.tp_brokerage_amt : "",
        reward_on:  data.reward_on != undefined && data.reward_on != null ? data.reward_on : "",
        reward_perc:  data.reward_percentage != undefined && data.reward_percentage != null ? data.reward_percentage : "",
        reward_amount:  data.reward_amt != undefined && data.reward_amt != null ? data.reward_amt : "",
        bonus_reward_perc:  data.bonus_percentage != undefined && data.bonus_percentage != null ? data.bonus_percentage : "",
        bonus_reward_amount:  data.bonus_amt != undefined && data.bonus_amt != null ? data.bonus_amt : "",
        gross_premium:  data.gross_premium != undefined && data.gross_premium != null ? data.gross_premium : "",
        total_brokerage_amount: data.total_brokerage_amt != undefined && data.total_brokerage_amt != null ? data.total_brokerage_amt : "",
      };

      arrQuestion.push(standardBrokerageDetails);
    }

    const arrQuestionDetail = arrQuestion.map((item) => {
      const newItem = { ...item };
      if (newItem.id === "") {
        delete newItem.id;
      }
      return newItem;
    });

    let isValid = true;
    questionList.forEach((item, index) => {
      if (
        item.share_premium_amount === "" ||
        item.share_premium_amount === null
      ) {
        $(`#error-${`share_premium_amount${index}`}`).removeClass("d-none");
        isValid = false;
        $(`#co-insurer-details-card`).addClass("show");
      } else {
        $(`#error-${`share_premium_amount${index}`}`).addClass("d-none");
      }

      if (item.share === "" || item.share === null) {
        $(`#error-${`share${index}`}`).removeClass("d-none");
        isValid = false;
        $(`#co-insurer-details-card`).addClass("show");
      } else {
        $(`#error-${`share${index}`}`).addClass("d-none");
      }

      if (item.type === "" || item.type === null) {
        $(`#error-${`type${index}`}`).removeClass("d-none");
        isValid = false;
        $(`#co-insurer-details-card`).addClass("show");
      } else {
        $(`#error-${`type${index}`}`).addClass("d-none");
      }

      if (
        closureType != null &&
        closureType === "Co-insurer" &&
        (item.insurer_id === "" || item.insurer_id === null)
      ) {
        $(`#error-${`insurer_id${index}`}`).removeClass("d-none");
        isValid = false;
        $(`#co-insurer-details-card`).addClass("show");
      } else {
        $(`#error-${`insurer_id${index}`}`).addClass("d-none");
      }

      if (
        closureType != null &&
        closureType === "Co-broking" &&
        (item.broker_id === "" || item.broker_id === null)
      ) {
        $(`#error-${`broker_id${index}`}`).removeClass("d-none");
        isValid = false;
        $(`#co-insurer-details-card`).addClass("show");
      } else {
        $(`#error-${`broker_id${index}`}`).addClass("d-none");
      }
    });
    data.name =
      departmentDetails?.name != null && departmentDetails?.name != undefined
        ? departmentDetails?.name
        : "";
    data.net_amount =
      data.net_amount != null && data.net_amount != undefined
        ? data.net_amount
        : "";
    data.id = detailsData.id;
    data.closure_type =
      closureType != null &&
        closureType != "null" &&
        quoteStatus != null &&
        quoteStatus != undefined &&
        quoteStatus === "Proposal Success"
        ? closureType
        : "";
    data.opportunity_questions =
      arrData != null && arrData.length > 0
        ? JSON.stringify(arrData)
        : data.opportunityQuestion != null
          ? JSON.stringify(data.opportunityQuestion)
          : JSON.stringify(arrSystemProbability);
    data.client_type = proposalPoolViewDetails?.client_type;
    data.client_name = proposalPoolViewDetails?.customer_name;
    data.policy_id = detailsData.id;
  
    data.tag_user_id = JSON.stringify(data.tag_user_id);

    data.opportunity_id = proposalPoolViewDetails?.id;
    data.policy_type_id = data.policy_type_id;
    data.business_closure_date = closureDate;
    data.policy_start_date =
      data.policy_start_date != null && data.policy_start_date != undefined
        ? data.policy_start_date
        : "";
    data.end_date =
      data.opportunity_status != "" &&
        data.opportunity_status != null &&
        closureType != null &&
        closureType != "null" &&
        closureType != "" &&
        data.end_date != null &&
        data.end_date != undefined
        ? data.end_date
        : "";
    data.insurer_id =
      opportunityType != "Fresh" &&
        data.insurer_id != null &&
        data.insurer_id != "null"
        ? data.insurer_id
        : "";
    data.department_id = departmentId;
    data.gst_amount =
      (data.gst_amount != null) & (data.gst_amount != undefined)
        ? data.gst_amount
        : "";
    data.expected_premium =
      data.expected_premium != undefined && data.expected_premium != null
        ? data.expected_premium
        : proposalPoolViewDetails?.expected_premium != null
          ? proposalPoolViewDetails?.expected_premium
          : "";
    data.od_net_premium =
      proposalPoolViewDetails?.opportunity_status === "Proposal Success" &&
        userId === proposalPoolViewDetails?.assigned_rm_id
        ? proposalPoolViewDetails?.opportunity_success_details?.premium_amount
        : data.premium_amount != undefined
          ? data.premium_amount
          : "";
    data.payment_sent_to_insurer =
      data.payment_sent_to_insurer != null &&
        data.payment_sent_to_insurer != undefined
        ? data.payment_sent_to_insurer
        : "";
    data.client_response =
      data.client_response != null && data.client_response != undefined
        ? data.client_response
        : "";
    data.total_brokerage = 0;
    // data.cgst = data.cgst != undefined && data.cgst != null ? data.cgst : "";
    // data.sgst = data.sgst != undefined && data.sgst != null ? data.sgst : "";
    data.reason =
      data.reason != undefined && data.reason != null ? data.reason : "";
    data.intermediary_type =
      data.intermediary_type != null && data.intermediary_type != undefined
        ? data.intermediary_type
        : "";
    data.intermediary_name =
      data.intermediary_name != undefined && data.intermediary_name != null
        ? data.intermediary_name
        : "";
    data.intermediary_value =
      data.intermediary_value != undefined && data.intermediary_value != null
        ? data.intermediary_value
        : "";
    data.gross_premium =
      data.gross_premium != undefined && data.gross_premium != null
        ? data.gross_premium
        : "";
    // data.pinc_gross_premium =
    //   data.pinc_gross_premium != undefined && data.pinc_gross_premium != null
    //     ? data.pinc_gross_premium
    //     : "";
    // data.pinc_share =
    //   data.pinc_share != null && data.pinc_share != undefined
    //     ? data.pinc_share
    //     : "";
    // data.pinc_share_premium =
    //   data.pinc_share_premium != undefined && data.pinc_share_premium != null
    //     ? data.pinc_share_premium
    //     : "";
    data.client_remark =
      data?.client_remark != undefined && data?.client_remark != null
        ? data?.client_remark
        : "";
    data.opportunity_remark =
      data.opportunity_remark != undefined && data.opportunity_remark != null
        ? data.opportunity_remark
        : "";
    data.placement_id =
      placementId != null && placementId != undefined ? placementId : "";
    data.instalment =
      data.instalment != null && data?.instalment != undefined
        ? data?.instalment
        : "";
    data.opportunity_intermediary_type =
      data.opportunity_intermediary_type != null &&
        data?.opportunity_intermediary_type != undefined
        ? data?.opportunity_intermediary_type
        : "";
    data.opportunity_insurer_id =
      quoteStatus != null &&
        quoteStatus != undefined &&
        quoteStatus === "Proposal Success" &&
        opportunityInsurerId != null &&
        opportunityInsurerId != undefined
        ? opportunityInsurerId
        : data.opportunity_insurer_id != null &&
          data.opportunity_insurer_id != undefined
          ? data.opportunity_insurer_id
          : "";
    data.opportunity_sub_status =
      data.opportunity_sub_status != null &&
        data.opportunity_sub_status != undefined
        ? data?.opportunity_sub_status
        : "";
    data.sales_remark =
      proposalPoolViewDetails?.closure?.sales_remark != null
        ? proposalPoolViewDetails?.closure?.sales_remark
        : data?.sales_remark != null && data?.sales_remark != undefined
          ? data?.sales_remark
          : "";
    data.client_id = proposalPoolViewDetails?.client_id;
    data.insurer_ids =
      data.insurer_ids != null && data.insurer_ids != undefined
        ? JSON.stringify(data.insurer_ids)
        : "";
    data.broker_ids =
      data.broker_ids != null && data.broker_ids != undefined
        ? JSON.stringify(data.broker_ids)
        : "";
    data.co_insurer_n_co_broking_reason =
      data.co_insurer_n_co_broking_reason != null &&
        data.co_insurer_n_co_broking_reason != undefined
        ? data?.co_insurer_n_co_broking_reason
        : "";
    data.is_product_specialist_required =
      proposalPoolViewDetails?.is_product_specialist_required;
    data.broking_insurer_id =
      data.broking_insurer_id != null && data.broking_insurer_id != undefined
        ? data.broking_insurer_id
        : "";
    if (
      proposalPoolViewDetails?.product_specialist_id === 0 ||
      proposalPoolViewDetails?.product_specialist_id === "" ||
      proposalPoolViewDetails?.product_specialist_id === null
    ) {
      data.opportunity_status = "Open";
    }
    var sendFiles = [];
    if (files != null && files.length > 0) {
      files.forEach((file, indexFile) => {
        sendFiles.push({ name: `documents[${indexFile}]`, value: file });
      });
    }

    if (
      quoteStatus === "Proposal Success" &&
      closureType != null && closureType === "Standard"
    ) {
      data.insurers = JSON.stringify(arrQuestionDetail);
      delete data.file;
      console.log(data);
    }
    if (
      quoteStatus === "Proposal Success" &&
      closureType != null &&
      (closureType === "Co-broking" || closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking")
    ) {
      if (questionList != null && questionList.length > 0) {
        if (isValid) {
          const hasFollowType = questionList.some(
            (item) => item?.type === "Lead"
          );
          const countFollowType = questionList.filter(
            (item) => item?.type === "Lead"
          ).length;
          const hasPINC = questionList.some((item) => item?.broker_id == 620);
          const totalPercentShare = arrQuestion.reduce(
            (total, item) => total + parseFloat(item?.share),
            0
          );
          const hasDuplicateInsurer = questionList.some(
            (item, index) =>
              questionList.findIndex(
                (innerItem) => innerItem?.insurer_id === item?.insurer_id
              ) !== index
          );
          const hasDuplicateBroker = questionList.some(
            (item, index) =>
              questionList.findIndex(
                (innerItem) => innerItem?.broker_id === item?.broker_id
              ) !== index
          );
          if (
            quoteStatus === "Proposal Success" &&
            closureType != null &&
            closureType === "Co-broking" &&
            !hasPINC
          ) {
            Swal.fire({
              title: "Alert",
              text: "PINC is missing from the Co-broking list",
              confirmButtonColor: PRIMARY_COLOR,
            }).then((success) => { });
          } else if (
            quoteStatus === "Proposal Success" &&
            closureType != null &&
            closureType === "Co-insurer" &&
            hasDuplicateInsurer
          ) {
            Swal.fire({
              title: "Alert",
              text: "Insurer name should be unique for each record.",
              confirmButtonColor: PRIMARY_COLOR,
            }).then((success) => { });
          } else if (
            quoteStatus === "Proposal Success" &&
            closureType != null &&
            closureType === "Co-broking" &&
            hasDuplicateBroker
          ) {
            Swal.fire({
              title: "Alert",
              text: "Broker name should be unique for each record.",
              confirmButtonColor: PRIMARY_COLOR,
            }).then((success) => { });
          } else if (
            (quoteStatus === "Proposal Success" &&
              closureType != null &&
              (closureType === "Co-broking" || closureType === "Co-insurer") &&
              !hasFollowType) ||
            countFollowType > 1
          ) {
            Swal.fire({
              title: "Alert",
              text: "There can be only one Lead, please correct the entry",
              confirmButtonColor: PRIMARY_COLOR,
            }).then((success) => { });
          } else if (
            quoteStatus === "Proposal Success" &&
            closureType != null &&
            (closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking")
          ) {
            if (totalPercentShare === 100) {
              data.insurers = JSON.stringify(arrQuestionDetail);
              delete data.file;
              $("#proposalpool-edit-loader").removeClass("hidden");
              $("#editproposal_pool").prop("disabled", true);
              console.log(data);
              props.actionProposalPoolEdit(data, sendFiles);
            } else {
              Swal.fire({
                title: "Message",
                text: "Addition of Share % should be equal to 100.",
                confirmButtonColor: PRIMARY_COLOR,
              }).then((success) => { });
            }
          } else if (
            quoteStatus === "Proposal Success" &&
            closureType != null &&
            closureType === "Co-broking"
          ) {
            if (totalPercentShare === 100) {
              data.insurers = JSON.stringify(arrQuestionDetail);
              delete data.file;
              console.log(data);
              $("#proposalpool-edit-loader").removeClass("hidden");
              $("#editproposal_pool").prop("disabled", true);
              props.actionProposalPoolEdit(data, sendFiles);
            } else {
              Swal.fire({
                title: "Message",
                text: "Addition of Share % should be equal to 100.",
                confirmButtonColor: PRIMARY_COLOR,
              }).then((success) => { });
            }
          }
        }
      } else {
        Swal.fire({
          title: "Proposal success error",
          text: `${closureType === "Co-insurer" ? "Co-Insurer" : "Co-Broking"
            } information is mandatory to add`,
          confirmButtonColor: PRIMARY_COLOR,
        }).then((success) => { });
      }
    } else if (
      quoteStatus === "Proposal Lost" &&
      Number(getProposalPlacement?.length) === 0
    ) {
      Swal.fire({
        title: "No Placement Details found!",
        text: "To mark this opportunity as Proposal Lost, at least one placement information is needed.",
        confirmButtonColor: PRIMARY_COLOR,
      }).then((success) => { });
    } else {
      delete data.file;
      console.log(data);
      $("#proposalpool-edit-loader").removeClass("hidden");
      $("#editproposal_pool").prop("disabled", true);
      props.actionProposalPoolEdit(data, sendFiles);
    }
  };

  return (
    <>
      <ScreenTitle title="Edit Opportunity" />
      <section>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <form
                  noValidate
                  autoComplete="Off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-body">
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Created Date&nbsp;
                            {/* <span className="danger">*</span> */}
                          </label>
                          <input
                            disabled
                            className="form-control"
                            id="created_at"
                            name="created_at"
                            value={proposalPoolViewDetails?.created_at}
                            {...register("created_at")}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Opportunity ID&nbsp;
                            <span className="danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="opportunity_id"
                            name="opportunity_id"
                            disabled={true}
                            value={proposalPoolViewDetails?.id}
                            {...register("opportunity_id")}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Client Type&nbsp;<span className="danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="client_type"
                            name="client_type"
                            disabled={true}
                            value={_.capitalize(
                              proposalPoolViewDetails?.client_type
                            )}
                            {...register("client_type")}
                          />
                        </div>
                      </div>
                      {opportunityType === "Renewal" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Control Number
                              </label>
                              <input
                                className="form-control"
                                id="control_number"
                                name="control_number"
                                disabled={true}
                                value={proposalPoolViewDetails?.control_no}
                                {...register("control_number")}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Policy Number
                              </label>
                              <input
                                className="form-control"
                                id="policy_no"
                                name="policy_no"
                                disabled={true}
                                value={proposalPoolViewDetails?.policy_no}
                                {...register("policy_no")}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Company Name&nbsp;<span className="danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="client_name"
                            name="client_name"
                            disabled={true}
                            value={proposalPoolViewDetails?.customer_name}
                            {...register("client_name")}
                          />
                        </div>
                      </div>
                      {opportunityType != "Renewal" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Opportunity Type&nbsp;
                              <span className="danger">*</span>
                            </label>
                            <Controller
                              name="opportunity_type"
                              control={control}
                              render={({ field }) => (
                                <DropDownListComponent
                                  {...field}
                                  id="opportunity_type"
                                  name="opportunity_type"
                                  dataSource={[
                                    {
                                      value: "Fresh",
                                      title: "Fresh",
                                    },
                                    {
                                      value: "Market Roll Over",
                                      title: "Market Roll Over",
                                    },
                                  ]}
                                  allowFiltering={false}
                                  placeholder="Select Opportunity Type"
                                  value={field.value}
                                  enabled={
                                    userId == 1 ||
                                      (proposalPoolViewDetails?.opportunity_status ===
                                        "Open" &&
                                        (userId ===
                                          proposalPoolViewDetails?.user_id ||
                                          userId ===
                                          proposalPoolViewDetails?.assigned_rm_id))
                                      ? true
                                      : false
                                  }
                                  fields={{ text: "title", value: "value" }}
                                  change={(event) => {
                                    if (event.itemData != null) {
                                      field.onChange(event.itemData.value);
                                      setOpportunityType(event.itemData.value);
                                    }
                                  }}
                                />
                              )}
                              rules={{
                                required: "Opportunity Type is required",
                              }}
                            />

                            {errors.opportunity_type && (
                              <ErrorLabel
                                message={errors.opportunity_type?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : opportunityType === "Renewal" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Opportunity Type
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="opportunity_type"
                              name="opportunity_type"
                              value={
                                opportunityType != null ? opportunityType : ""
                              }
                              disabled={userId == 1 ? false : true}
                              placeholder="Enter Opportunity Type"
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Policy Type&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="policy_type_id"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={policyGroup}
                                id="policy_type_id"
                                name="policy_type_id"
                                fields={{ text: "name", value: "id" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                placeholder="Select Policy Type"
                                value={field.value}
                                enabled={
                                  userId == 1 ||
                                    (proposalPoolViewDetails?.opportunity_status ===
                                      "Open" &&
                                      (userId ===
                                        proposalPoolViewDetails?.user_id ||
                                        userId ===
                                        proposalPoolViewDetails?.assigned_rm_id))
                                    ? true
                                    : false
                                }
                                change={(event) => {
                                  if (event.itemData != null) {
                                    setPolicy(event.itemData.name);
                                    setDepartment(
                                      event.itemData.department_name
                                    );
                                    setDepartmentId(
                                      event.itemData.department_id
                                    );
                                    field.onChange(event.itemData.id);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Policy Type is required",
                            }}
                          />
                          {errors.policy_type_id && (
                            <ErrorLabel
                              message={errors.policy_type_id?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Department</label>
                          <input
                            type="text"
                            className="form-control"
                            id="department_name"
                            name="department_name"
                            value={department != null ? department : ""}
                            disabled={true}
                            placeholder="Enter Department"
                          />
                        </div>
                      </div>
                      {opportunityType != null && opportunityType != "Fresh" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Intermediary Type
                              </label>
                              <Controller
                                name="intermediary_type"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={intermediaryTypeList}
                                    id="intermediary_type"
                                    name="intermediary_type"
                                    fields={{ text: "name", value: "name" }}
                                    allowFiltering={true}
                                    filterType={"Contains"}
                                    value={field.value}
                                    enabled={enabledValue}
                                    placeholder="Select Intermediary Type"
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.name);
                                        setIntermediaryType(
                                          event.itemData.name
                                        );
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.intermediary_type && (
                                <ErrorLabel
                                  message={errors.intermediary_type?.message}
                                />
                              )}
                            </div>
                          </div>
                          {intermediaryType != null &&
                            intermediaryType != "Broker" ? (
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Intermediary Details
                                </label>
                                <input
                                  className="form-control"
                                  id="intermediary_value"
                                  name="intermediary_value"
                                  disabled={!enabledValue}
                                  placeholder="Enter Intermediary Details"
                                  {...register("intermediary_value")}
                                />
                                {errors.intermediary_value && (
                                  <ErrorLabel
                                    message={errors.intermediary_value?.message}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {intermediaryType != null &&
                            intermediaryType === "Broker" ? (
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Broker's name
                                </label>
                                <Controller
                                  name="intermediary_value"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      dataSource={getBrokerName}
                                      id="intermediary_value"
                                      name="intermediary_value"
                                      fields={{ text: "name", value: "name" }}
                                      allowFiltering={true}
                                      filterType={"Contains"}
                                      value={field.value}
                                      enabled={enabledValue}
                                      placeholder="Select Broker's name"
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.name);
                                        }
                                      }}
                                    />
                                  )}
                                />
                                {errors.intermediary_value && (
                                  <ErrorLabel
                                    message={errors.intermediary_value?.message}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {opportunityType != null && opportunityType != "Fresh" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Insurance Company
                              </label>
                              <Controller
                                name="insurer_id"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={departmentWiseInsurer}
                                    id="insurer_id"
                                    name="insurer_id"
                                    fields={{ text: "name", value: "id" }}
                                    allowFiltering={true}
                                    filterType={"Contains"}
                                    placeholder="Select Insurance Company"
                                    value={field.value}
                                    enabled={enabledValue}
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.id);
                                      }
                                    }}
                                  />
                                )}
                              />
                              {errors.insurer_id && (
                                <ErrorLabel
                                  message={errors.insurer_id?.message}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Expected Premium&nbsp;
                            <span
                              className={`danger ${opportunityType != "Fresh" ? "" : "hidden"
                                }`}
                            >
                              *
                            </span>
                          </label>
                          <input
                            min={0}
                            className="form-control"
                            id="expected_premium"
                            name="expected_premium"
                            disabled={!enabledValue}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(event) => {
                              const pastedText =
                                event.clipboardData.getData("text/plain");
                              const textWithoutCommas = pastedText.replace(
                                /,/g,
                                ""
                              );
                              if (!/^\d+$/.test(textWithoutCommas)) {
                                event.preventDefault();
                              } else {
                                document.execCommand(
                                  "insertText",
                                  false,
                                  textWithoutCommas
                                );
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Expected Premium"
                            {...register("expected_premium", {
                              required:
                                opportunityType != "Fresh"
                                  ? "Expected Premium is required"
                                  : "",
                            })}
                          />
                          <div className="font-small-2 text-success mt-half">
                            {numberInWords != "Zero" && numberInWords != 0
                              ? numberInWords
                              : ""}
                          </div>
                          {errors.expected_premium && (
                            <ErrorLabel
                              message={errors.expected_premium?.message}
                            />
                          )}
                        </div>
                      </div>
                      {opportunityType != null && opportunityType != "Fresh" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Renewal Date&nbsp;
                              <span
                                className={`danger ${opportunityType != "Fresh" ? "" : "hidden"
                                  }`}
                              >
                                *
                              </span>
                            </label>
                            <Controller
                              name="expiring_date"
                              control={control}
                              render={({ field }) => (
                                <DateBox
                                  calendarOptions={{
                                    showTodayButton: true,
                                  }}
                                  {...field}
                                  id="expiring_date"
                                  name="expiring_date"
                                  stylingMode="underlined"
                                  min={moment(new Date())
                                    .subtract(2, "M")
                                    .format("YYYY-MM-DD")}
                                  className="form-control"
                                  pickerType="calendar"
                                  type="date"
                                  value={field.value}
                                  disabled={
                                    userId == 1 ||
                                      proposalProductRight === true ||
                                      (proposalPoolViewDetails?.opportunity_status ===
                                        "Open" &&
                                        (userId ===
                                          proposalPoolViewDetails?.user_id ||
                                          userId ===
                                          proposalPoolViewDetails?.assigned_rm_id))
                                      ? false
                                      : true
                                  }
                                  acceptCustomValue={false}
                                  displayFormat="dd/MM/yyyy"
                                  showClearButton={true}
                                  onValueChange={(value) => {
                                    field.onChange(
                                      dateFormat(value, "yyyy-mm-dd")
                                    );
                                    // setExpiringDate(
                                    //   dateFormat(value, "yyyy-mm-dd")
                                    // );
                                  }}
                                />
                              )}
                              rules={{
                                required:
                                  opportunityType != "Fresh"
                                    ? "Renewal Date is required"
                                    : "",
                              }}
                            />
                            {errors.expiring_date && (
                              <ErrorLabel
                                message={errors.expiring_date?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : opportunityType != null &&
                        opportunityType === "Fresh" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Expected Closure Date&nbsp;
                              <span className="danger">*</span>
                            </label>
                            <Controller
                              name="expiring_date"
                              control={control}
                              render={({ field }) => (
                                <DateBox
                                  calendarOptions={{
                                    showTodayButton: true,
                                  }}
                                  {...field}
                                  id="expiring_date"
                                  name="expiring_date"
                                  stylingMode="underlined"
                                  min={moment()
                                    .startOf("month")
                                    .format("YYYY-MM-DD")}
                                  className="form-control"
                                  pickerType="calendar"
                                  type="date"
                                  value={field.value}
                                  disabled={
                                    userId == 1 ||
                                      proposalProductRight === true ||
                                      (proposalPoolViewDetails?.opportunity_status ===
                                        "Open" &&
                                        (userId ===
                                          proposalPoolViewDetails?.user_id ||
                                          userId ===
                                          proposalPoolViewDetails?.assigned_rm_id))
                                      ? false
                                      : true
                                  }
                                  acceptCustomValue={false}
                                  displayFormat="dd/MM/yyyy"
                                  showClearButton={true}
                                  onValueChange={(value) => {
                                    field.onChange(
                                      dateFormat(value, "yyyy-mm-dd")
                                    );
                                    // setExpiringDate(
                                    //   dateFormat(value, "yyyy-mm-dd")
                                    // );
                                  }}
                                />
                              )}
                              rules={{
                                required:
                                  opportunityType === "Fresh"
                                    ? "Expected Closure Date is required"
                                    : "",
                              }}
                            />
                            {errors.expiring_date && (
                              <ErrorLabel
                                message={errors.expiring_date?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {expiringDate && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Proposal Month
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={CalculateProposalMonth(
                                getValues("expiring_date")
                              )}
                            />
                          </div>
                        </div>
                      )} */}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            RM Probability (in %)
                          </label>
                          <Controller
                            name="probability"
                            key={random(5425245)}
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={probabilityGroup}
                                id="probability"
                                name="probability"
                                fields={{ text: "title", value: "value" }}
                                allowFiltering={false}
                                placeholder="Select Probability"
                                value={field.value}
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                    setProbability(event.itemData.value);
                                    props.actionOpportunityQuestionsList({});
                                  }
                                }}
                              />
                            )}
                          />
                          {errors.probability && (
                            <ErrorLabel message={errors.probability?.message} />
                          )}
                        </div>
                      </div>

                      {probability != null &&
                        Number(probability) >= 50 &&
                        opportunityQuestionFields != null &&
                        opportunityQuestionFields.length > 0 ? (
                        opportunityQuestionFields.map((item, index) => (
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                {item.question}
                                <span
                                  className={`danger ${isOpportunityQuestion != true
                                      ? ""
                                      : "hidden"
                                    }`}
                                >
                                  *
                                </span>
                              </label>
                              <Controller
                                name={`opportunityQuestion[${index}].question_value`}
                                id={`opportunityQuestion[${index}].question_value`}
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        title: "Yes",
                                        value: "Yes",
                                      },
                                      {
                                        title: "No",
                                        value: "No",
                                      },
                                    ]}
                                    fields={{ text: "title", value: "value" }}
                                    value={item.question_value}
                                    placeholder="Select"
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.value);
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required:
                                    isOpportunityQuestion != true
                                      ? true
                                      : false,
                                }}
                              />
                              {errors.opportunityQuestion?.[index]
                                ?.question_value && (
                                  <ErrorLabel message={"Please Select details"} />
                                )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}

                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Tag Team Member</label>
                          <Controller
                            name="tag_user_id"
                            control={control}
                            render={({ field }) => (
                              <MultiSelectComponent
                                {...field}
                                dataSource={getTagUser}
                                id="tag_user_id"
                                name="tag_user_id"
                                fields={{ text: "full_name", value: "user_id" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                value={field.value}
                                placeholder="Select Tag User"
                                change={(event) => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);                                 
                                  }
                                  console.log(event,"shdjksd")
                                }}
                              />
                            )}
                          />
                          {errors.tag_user_id && (
                            <ErrorLabel message={errors.tag_user_id?.message} />
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-lg-4 pt-1 mb-0 pb-0">
                        <div className="form-group">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="is_product_specialist_required"
                              id="is_product_specialist_required"
                              disabled={true}
                              defaultChecked={true}
                              {...register("is_product_specialist_required")}
                            />
                            <label
                              class="custom-control-label"
                              for="is_product_specialist_required"
                            >
                              Is Product Specialist Required?
                            </label>
                          </div>
                        </div>
                      </div>
                      {policy != null && policy != "" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Assign Product RM
                            </label>
                            <Controller
                              name="product_specialist_id"
                              control={control}
                              render={({ field }) => (
                                <DropDownListComponent
                                  {...field}
                                  dataSource={getAssignProductRM}
                                  id="product_specialist_id"
                                  name="product_specialist_id"
                                  fields={{
                                    text: "full_name",
                                    value: "user_id",
                                  }}
                                  allowFiltering={true}
                                  filterType={"Contains"}
                                  popupHeight={"200px"}
                                  placeholder="Assign Product RM"
                                  value={field.value}
                                  change={(event) => {
                                    if (event.itemData != null) {
                                      field.onChange(event.itemData.user_id);
                                    }
                                  }}
                                />
                              )}
                              rules={{
                                required: "Please select assign product RM",
                              }}
                            />
                            {errors.product_specialist_id && (
                              <ErrorLabel
                                message={errors.product_specialist_id?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">RM Remarks</label>
                          <textarea
                            className="form-control"
                            id="client_remark"
                            name="client_remark"
                            placeholder="Enter RM Remark"
                            disabled={
                              userId == 1 ||
                                userId === proposalPoolViewDetails?.user_id ||
                                userId === proposalPoolViewDetails?.assigned_rm_id
                                ? false
                                : true
                            }
                            {...register("client_remark")}
                            rows="5"
                          />
                          {errors.client_remark && (
                            <ErrorLabel
                              message={errors.client_remark?.message}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* { */}
                  {proposalPoolViewDetails?.product_specialist_id != 0 &&
                    proposalPoolViewDetails?.product_specialist_id != "" &&
                    proposalPoolViewDetails?.product_specialist_id != null ? (
                    <>
                      <div className="col-12 col-md-12 col-lg-12 pl-0">
                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                          <span>Placement Details</span>
                          {userId === proposalPoolViewDetails?.assigned_rm_id &&
                            // userId != proposalPoolViewDetails?.product_specialist_id
                            proposalProductRight != true ? (
                            <></>
                          ) : (
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                setShowAddPlacement(true);
                                setPlacementData(null);
                              }}
                            >
                              <span>
                                <FiPlus cursor="pointer" size={18} />
                                &nbsp;Placement Add
                              </span>
                            </button>
                          )}
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                          <DataGrid
                            dataSource={getProposalPlacement}
                            showBorders={true}
                            // sorting={false}
                            columnAutoWidth={true}
                          >
                            {renderColumns()}
                            <Pager
                              visible={true}
                              showPageSizeSelector={false}
                              showNavigationButtons={true}
                              allowedPageSizes={[4, 6, 10]}
                            />
                            <Paging
                              defaultPageIndex={0}
                              defaultPageSize={10}
                              enabled={true}
                            />
                          </DataGrid>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {proposalPoolViewDetails?.product_specialist_id != 0 &&
                    proposalPoolViewDetails?.product_specialist_id != "" &&
                    proposalPoolViewDetails?.product_specialist_id != null ? (
                    <>
                      <div className="row">
                        {(proposalPoolViewDetails?.opportunity_status ===
                          "Quote Under Process" ||
                          proposalPoolViewDetails?.opportunity_status ===
                          "Quote Rejected" ||
                          proposalPoolViewDetails?.opportunity_status ===
                          "Proposal Success") &&
                          (userId === proposalPoolViewDetails?.assigned_rm_id ||
                            userId === proposalPoolViewDetails?.user_id) ? (
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Current Quote Status
                              </label>
                              <input
                                className="form-control"
                                disabled={userId == 1 ? false : true}
                                value={
                                  proposalPoolViewDetails?.opportunity_status
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control pl-0">
                              Quote Status&nbsp;
                              <span
                                className={`text-danger ${proposalPoolViewDetails?.opportunity_status !=
                                    "Proposal Success"
                                    ? ""
                                    : "hidden"
                                  }`}
                              >
                                *
                              </span>
                            </label>
                            <Controller
                              name="opportunity_status"
                              control={control}
                              render={({ field }) => (
                                <DropDownListComponent
                                  {...field}
                                  id="opportunity_status"
                                  name="opportunity_status"
                                  dataSource={quoteStatusDropdown}
                                  allowFiltering={false}
                                  placeholder="Select Quote Status"
                                  value={field.value}
                                  // enabled={
                                  //  ( proposalPoolViewDetails?.opportunity_status === "Proposal Lost" &&
                                  //   (userId === 1 || userId === proposalPoolViewDetails?.product_specialist_id))
                                  //  }
                                  fields={{ text: "name", value: "name" }}
                                  change={(event) => {
                                    if (event.itemData != null) {
                                      if (
                                        proposalPoolViewDetails?.opportunity_status ===
                                        "Proposal Success" &&
                                        resetData != true &&
                                        (event.itemData.name ===
                                          "Proposal Lost" ||
                                          event.itemData.name === "Open")
                                      ) {
                                        Swal.fire({
                                          icon: "warning",
                                          text: `You are changing a converted proposal to ${event.itemData.name}. Any previous payment & Insurer details will be lost`,
                                          cancelButtonText: "Cancel",
                                          showCancelButton: true,
                                          confirmButtonColor: PRIMARY_COLOR,
                                        }).then(({ isConfirmed, value }) => {
                                          if (isConfirmed) {
                                            field.onChange(event.itemData.name);
                                            setQuoteStatus(event.itemData.name);
                                            setClosureType(null);
                                            setValue("closure_type", null);
                                            setValue(
                                              "opportunity_sub_status",
                                              null
                                            );
                                            setResetData(true);
                                            setValue(
                                              "opportunity_insurer_id",
                                              null
                                            );
                                          } else {
                                            setQuoteStatus(
                                              proposalPoolViewDetails?.opportunity_status
                                            );
                                            setValue(
                                              "opportunity_status",
                                              proposalPoolViewDetails?.opportunity_status
                                            );
                                            setValue(
                                              "closure_type",
                                              proposalPoolViewDetails?.closure_type
                                            );
                                            setClosureType(
                                              proposalPoolViewDetails?.closure_type
                                            );
                                            setResetData(false);
                                          }
                                        });
                                      } else {
                                        field.onChange(event.itemData.name);
                                        setQuoteStatus(event.itemData.name);
                                        setClosureType(null);
                                        setValue("closure_type", null);
                                        setValue(
                                          "opportunity_sub_status",
                                          null
                                        );
                                      }
                                    }
                                  }}
                                />
                              )}
                              rules={{
                                required: "Quote Status is required",
                              }}
                            />

                            {errors.opportunity_status && (
                              <ErrorLabel
                                message={errors.opportunity_status?.message}
                              />
                            )}
                          </div>
                        </div>

                        {quoteStatus === "Quote Rejected" ? (
                          <div div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control pl-0">
                                Rejection Reason&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="opportunity_sub_status"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    id="opportunity_sub_status"
                                    name="opportunity_sub_status"
                                    dataSource={quoteRejectStatusDropdown}
                                    allowFiltering={false}
                                    placeholder="Select Rejection Reason"
                                    value={field.value}
                                    fields={{ text: "name", value: "name" }}
                                    change={(event) => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.name);
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Reason is required",
                                }}
                              />
                              {errors.opportunity_sub_status && (
                                <ErrorLabel
                                  message={
                                    errors.opportunity_sub_status?.message
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* PROPOSAL SUCCESS START*/}
                        {quoteStatus === "Proposal Success" ? (
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control pl-0">
                                Closure Type&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="closure_type"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    id="closure_type"
                                    name="closure_type"
                                    dataSource={closureTypeList}
                                    allowFiltering={false}
                                    placeholder="Select Closure Type"
                                    value={field.value}
                                    enabled={
                                      proposalPoolViewDetails?.opportunity_status ===
                                        "Proposal Success" &&
                                        userId ===
                                        proposalPoolViewDetails?.assigned_rm_id
                                        ? false
                                        : true
                                    }
                                    fields={{ text: "name", value: "name" }}
                                    change={(event) => {
                                      if (event.itemData != null && event?.e != null) {
                                        setQuestionList([])
                                        field.onChange(event.itemData.name);
                                        setClosureType(event.itemData.name);
                                        // if (
                                        //   proposalPoolViewDetails?.opportunity_status ===
                                        //   "Proposal Success"
                                        // ) {
                                        //   setQuestionList([]); 
                                        // }
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Closure Type is required",
                                }}
                              />

                              {errors.closure_type && (
                                <ErrorLabel
                                  message={errors.closure_type?.message}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {quoteStatus === "Proposal Success" &&
                          closureType === "Standard" ? (
                          <>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control pl-0">
                                  Insurer Business booked with&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="opportunity_insurer_id"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      id="opportunity_insurer_id"
                                      name="opportunity_insurer_id"
                                      dataSource={getInsuranceSuccessList}
                                      allowFiltering={false}
                                      placeholder="Select Insurer"
                                      value={field.value}
                                      enabled={
                                        proposalPoolViewDetails?.opportunity_status ===
                                          "Proposal Success" &&
                                          userId ===
                                          proposalPoolViewDetails?.assigned_rm_id
                                          ? false
                                          : true
                                      }
                                      fields={{
                                        text: "insurer_name",
                                        value: "insurer_id",
                                      }}
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          setPremium(
                                            event.itemData?.quote_amount
                                          );
                                          field.onChange(event.itemData.insurer_id);
                                          setPlacementId(event.itemData.id);
                                          setOpportunityInsurerId(
                                            event.itemData.insurer_id
                                          );
                                          if (
                                            event.itemData.id ===
                                            proposalPoolViewDetails?.opportunity_success_details
                                              ?.placement_id
                                          ) {
                                            setValue(
                                              "premium_amount",
                                              proposalPoolViewDetails?.opportunity_success_details
                                                ?.premium_amount != null
                                                ? proposalPoolViewDetails
                                                  ?.opportunity_success_details?.premium_amount
                                                : event.itemData?.quote_amount
                                            );
                                          } else {
                                            setValue(
                                              "premium_amount",
                                              event.itemData?.quote_amount
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required: "Insurer is required",
                                  }}
                                />
                                {errors.opportunity_insurer_id && (
                                  <ErrorLabel
                                    message={
                                      errors.opportunity_insurer_id?.message
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ) : 
                          <></>
                        }

                        {quoteStatus === "Proposal Success" &&
                        (closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking") ?
                        <>
                        <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control pl-0">
                                Brokerage Paid By&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="brokerage_paid_by"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    id="brokerage_paid_by"
                                    name="brokerage_paid_by"
                                    dataSource={[
                                      {
                                        name: "Lead",
                                        id: "Lead",
                                      },
                                      {
                                        name: "Follow",
                                        id: "Follow",
                                      },
                                    ]}
                                    allowFiltering={false}
                                    placeholder="Select Brokerage Paid By"
                                    value={field.value}
                                    fields={{ text: "name", value: "id" }}
                                    change={(event) => {
                                      if (event?.itemData != null) {
                                        field.onChange(event.itemData.id);
                                        setBrokeragePaidBy(event.itemData.id)
                                        setQuestionList([])
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Brokerage Paid By is required",
                                }}
                              />

                              {errors.brokerage_paid_by && (
                                <ErrorLabel
                                  message={errors.brokerage_paid_by?.message}
                                />
                              )}
                            </div>
                          </div>
                      </>:<></>}   
                     
                        {quoteStatus === "Proposal Success" &&
                          closureType != null ? (
                          <>
                            {" "}
       
                              <div className="col-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label className="label-control">
                                    OD Premium (Quote Amt (Net)&nbsp;
                                    <span className="danger">*</span>
                                  </label>
                                  <input
                                    className="form-control"
                                    id="premium_amount"
                                    name="premium_amount"
                                    placeholder="Enter Premium Amount"
                                    onKeyPress={(event) => {
                                      if (!/[0-9.]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    disabled={
                                      proposalPoolViewDetails?.opportunity_status ===
                                        "Proposal Success" &&
                                      userId ===
                                        proposalPoolViewDetails?.assigned_rm_id
                                        ? true
                                        : false
                                    }
                                    onPaste={(event) => {
                                      const pastedText =
                                        event.clipboardData.getData(
                                          "text/plain"
                                        );
                                      const textWithoutCommas =
                                        pastedText.replace(/,/g, "");
                                      if (
                                        !/^\d*\.?\d*$/.test(textWithoutCommas)
                                      ) {
                                        event.preventDefault();
                                      } else {
                                        document.execCommand(
                                          "insertText",
                                          false,
                                          textWithoutCommas
                                        );
                                        event.preventDefault();
                                      }
                                    }}
                                    {...register("premium_amount", {
                                      // required:'Enter 0 if nothing to declare'
                                    })}
                                  />
                                  <p
                                    className="hidden text-danger font-small-2"
                                    id="error-premium"
                                  >
                                    premium Amount must be greater than 0
                                  </p>
                                  {errors.premium_amount && (
                                    <ErrorLabel
                                      message={errors.premium_amount?.message}
                                    />
                                  )}
                                </div>
                              </div>


                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      TP Premium&nbsp;
                                      <span className="danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      id="tp_net_premium"
                                      name="tp_net_premium"
                                      placeholder="Enter TP Premium"
                                      onKeyPress={(event) => {
                                        if (!/[0-9.]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onPaste={(event) => {
                                        const pastedText =
                                          event.clipboardData.getData(
                                            "text/plain"
                                          );
                                        const textWithoutCommas =
                                          pastedText.replace(/,/g, "");
                                        if (
                                          !/^\d*\.?\d*$/.test(textWithoutCommas)
                                        ) {
                                          event.preventDefault();
                                        } else {
                                          document.execCommand(
                                            "insertText",
                                            false,
                                            textWithoutCommas
                                          );
                                          event.preventDefault();
                                        }
                                      }}
                                      {...register("tp_net_premium", {
                                        required:'Enter 0 if nothing to declare'
                                      })}
                                    />
                                    {errors.tp_net_premium && (
                                      <ErrorLabel
                                        message={errors.tp_net_premium?.message}
                                      />
                                    )}
                                  </div>
                                </div> 
                                <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                Net Premium &nbsp;
                                      <span className="danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  id="total_net_premium"
                                  name="total_net_premium"
                                  readOnly
                                  placeholder="Enter Gross Premium"
                                  {...register("total_net_premium")}
                                />
                              </div>
                            </div>
                           
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">CGST %</label>
                                <input
                                  className="form-control"
                                  id="cgst"
                                  name="cgst"
                                  placeholder="Enter CGST"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    const pastedText =
                                      event.clipboardData.getData("text/plain");
                                    const textWithoutCommas =
                                      pastedText.replace(/,/g, "");
                                    if (!/^\d+$/.test(textWithoutCommas)) {
                                      event.preventDefault();
                                    } else {
                                      document.execCommand(
                                        "insertText",
                                        false,
                                        textWithoutCommas
                                      );
                                      event.preventDefault();
                                    }
                                  }}
                                  {...register("cgst")}
                                />
                                {errors.cgst && (
                                  <ErrorLabel message={errors.cgst?.message} />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">SGST %</label>
                                <input
                                  className="form-control"
                                  id="sgst"
                                  name="sgst"
                                  placeholder="Enter SGST"
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    const pastedText =
                                      event.clipboardData.getData("text/plain");
                                    const textWithoutCommas =
                                      pastedText.replace(/,/g, "");
                                    if (!/^\d+$/.test(textWithoutCommas)) {
                                      event.preventDefault();
                                    } else {
                                      document.execCommand(
                                        "insertText",
                                        false,
                                        textWithoutCommas
                                      );
                                      event.preventDefault();
                                    }
                                  }}
                                  {...register("sgst")}
                                />
                                {errors.sgst && (
                                  <ErrorLabel message={errors.sgst?.message} />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Gross Premium
                                </label>
                                <input
                                  className="form-control"
                                  id="gross_premium"
                                  name="gross_premium"
                                  readOnly
                                  placeholder="Enter Gross Premium"
                                  {...register("gross_premium")}
                                />
                                {errors.gross_premium && (
                                  <ErrorLabel
                                    message={errors.gross_premium?.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control pl-0">
                                  Installment&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="instalment"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      id="instalment"
                                      name="instalment"
                                      dataSource={installment}
                                      allowFiltering={false}
                                      enabled={
                                        proposalPoolViewDetails?.opportunity_status ===
                                          "Proposal Success" &&
                                          userId ===
                                          proposalPoolViewDetails?.assigned_rm_id
                                          ? false
                                          : true
                                      }
                                      placeholder="Select Installment"
                                      value={field.value}
                                      fields={{ text: "name", value: "name" }}
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.name);
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required: "Installment is required",
                                  }}
                                />
                                {errors.instalment && (
                                  <ErrorLabel
                                    message={errors.instalment?.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Business Closure Date&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="business_closure_date"
                                  className="form-control"
                                  control={control}
                                  render={({ field }) => (
                                    <DateBox
                                      defaultValue={new Date()}
                                      calendarOptions={{
                                        showTodayButton: true,
                                      }}
                                      stylingMode="underlined"
                                      className="form-control"
                                      pickerType="calendar"
                                      min={moment(new Date())
                                        .subtract(2, "M")
                                        .format("YYYY-MM-DD")}
                                      type="date"
                                      displayFormat="dd/MM/yyyy"
                                      showClearButton={false}
                                      acceptCustomValue={false}
                                      value={closureDate}
                                      onValueChange={(value) => {
                                        setBusinessClosureDate(
                                          moment(value).format("yyyy-MM-DD")
                                        );
                                        field.onChange(
                                          moment(value).format("yyyy-MM-DD")
                                        );
                                        var strClosureDate =
                                          moment(value).format("yyyy-MM-DD");
                                        setClosureDate(strClosureDate);
                                        setPolicyDate(
                                          moment(value).format("yyyy-MM-DD")
                                        );
                                        setValue(
                                          "business_closure_date",
                                          strClosureDate
                                        );
                                        setValue(
                                          "policy_start_date",
                                          strClosureDate
                                        );
                                        const defaultEndDate = moment(value)
                                          .add(1, "year")
                                          .format("YYYY-MM-DD");
                                        setValue("end_date", defaultEndDate);
                                        setPolicyEndDate(
                                          moment(defaultEndDate)
                                        );
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Policy Start Date&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="policy_start_date"
                                  control={control}
                                  className="form-control"
                                  render={({ field }) => (
                                    <DateBox
                                      calendarOptions={{
                                        showTodayButton: true,
                                      }}
                                      {...field}
                                      defaultValue={businessClosureDate}
                                      id="policy_start_date"
                                      name="policy_start_date"
                                      stylingMode="underlined"
                                      className="form-control"
                                      pickerType="calendar"
                                      type="date"
                                      acceptCustomValue={false}
                                      value={field.value}
                                      displayFormat="dd/MM/yyyy"
                                      placeholder="Policy Start date"
                                      showClearButton={false}
                                      min={moment(new Date())
                                        .subtract(2, "M")
                                        .format("YYYY-MM-DD")}
                                      max={moment(new Date())
                                        .add(90, "days")
                                        .format("YYYY-MM-DD")}
                                      onValueChange={(value) => {
                                        const selectedDate = moment(value);
                                        if (
                                          policyEndDate &&
                                          selectedDate.isAfter(policyEndDate)
                                        ) {
                                          setPolicyEndDate(null);
                                          Swal.fire({
                                            title: "Error",
                                            text: "Start date can't be greater than End date",
                                            icon: "error",
                                          }).then((success) => { });
                                        } else {
                                          field.onChange(
                                            moment(value).format("YYYY-MM-DD")
                                          );
                                          setPolicyDate(selectedDate);

                                          // Set default end date 1 year from the start date
                                          const defaultEndDate = moment(value)
                                            .add(1, "year")
                                            .format("YYYY-MM-DD");
                                          setValue("end_date", defaultEndDate);
                                          setPolicyEndDate(
                                            moment(defaultEndDate)
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required: "Please select start date",
                                  }}
                                />
                                {errors.policy_start_date && (
                                  <ErrorLabel
                                    message={errors.policy_start_date?.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Policy End Date&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="end_date"
                                  control={control}
                                  className="form-control"
                                  render={({ field }) => (
                                    <DateBox
                                      calendarOptions={{
                                        showTodayButton: true,
                                      }}
                                      {...field}
                                      defaultValue={policyEndDate}
                                      id="end_date"
                                      name="end_date"
                                      stylingMode="underlined"
                                      className="form-control"
                                      pickerType="calendar"
                                      type="date"
                                      acceptCustomValue={false}
                                      value={field.value}
                                      displayFormat="dd/MM/yyyy"
                                      placeholder="End date"
                                      showClearButton={false}
                                      onValueChange={(value) => {
                                        const selectedDate = moment(value);
                                        if (
                                          policyDate &&
                                          selectedDate.isBefore(policyDate)
                                        ) {
                                          Swal.fire({
                                            title: "Error",
                                            text: "End date must be greater than Start date",
                                            icon: "error",
                                          }).then((success) => { });
                                        } else {
                                          field.onChange(
                                            moment(value).format("YYYY-MM-DD")
                                          );
                                          setPolicyEndDate(selectedDate);
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required: "Please select end date",
                                  }}
                                />
                                {errors.end_date && (
                                  <ErrorLabel
                                    message={errors.end_date?.message}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* START COMMENTED SUCCESS CO BROKING CO INSURER & CO _ BROKING & CO INSURE FLOW */}
                        {quoteStatus === "Proposal Success" &&
                          closureType != null &&
                          closureType === "Co-insurer & Co-broking" ? (
                          <>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">Remarks</label>
                                <input
                                  className="form-control"
                                  id="co_insurer_n_co_broking_reason"
                                  name="co_insurer_n_co_broking_reason"
                                  placeholder="Enter Reason"
                                  {...register(
                                    "co_insurer_n_co_broking_reason",
                                    {
                                      // required: proposalLostReason === "Others" ? "Enter Other Reason" : false
                                    }
                                  )}
                                />
                                {errors.co_insurer_n_co_broking_reason && (
                                  <ErrorLabel
                                    message={
                                      errors.co_insurer_n_co_broking_reason
                                        ?.message
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {/* END COMMENTED SUCCESS CO BROKING CO INSURER & CO _ BROKING & CO INSURE FLOW */}

                        {/* PROPOSAL SUCCESS END */}
                        {quoteStatus === "Proposal Lost" ? (
                          <>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control pl-0">
                                  Reason&nbsp;<span className="danger">*</span>
                                </label>
                                <Controller
                                  name="opportunity_sub_status"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      id="opportunity_sub_status"
                                      name="opportunity_sub_status"
                                      dataSource={lostStatusDropdown}
                                      allowFiltering={false}
                                      placeholder="Select  Reason"
                                      value={field.value}
                                      fields={{ text: "name", value: "name" }}
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.name);
                                          setProposalLostReason(
                                            event.itemData.name
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required: "Reason is required",
                                  }}
                                />
                                {errors.opportunity_sub_status && (
                                  <ErrorLabel
                                    message={
                                      errors.opportunity_sub_status?.message
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {proposalLostReason === "Others" ? (
                              <div className="col-12 col-md-4 col-lg-4 mt-2">
                                <div className="form-group">
                                  {/* <label className="label-control">RM Remarks</label> */}
                                  <input
                                    className="form-control"
                                    id="reason"
                                    name="reason"
                                    placeholder="Enter Other Reason"
                                    {...register("reason", {
                                      required:
                                        proposalLostReason === "Others"
                                          ? "Enter Other Reason"
                                          : false,
                                    })}
                                  />
                                  {errors.reason && (
                                    <ErrorLabel
                                      message={errors.reason?.message}
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Competition Intermediary Type&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="opportunity_intermediary_type"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      dataSource={intermediaryTypeList}
                                      id="opportunity_intermediary_type"
                                      name="opportunity_intermediary_type"
                                      fields={{ text: "name", value: "name" }}
                                      allowFiltering={true}
                                      filterType={"Contains"}
                                      value={field.value}
                                      placeholder="Select Intermediary Type"
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.name);
                                          setCompetitionIntermediaryType(
                                            event.itemData.name
                                          );
                                          // setValue('intermediary_name', '');
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required:
                                      "Competitive Intermediary Type is required",
                                  }}
                                />
                                {errors.opportunity_intermediary_type && (
                                  <ErrorLabel
                                    message={
                                      errors.opportunity_intermediary_type
                                        ?.message
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {competitionIntermediaryType === "Broker" ? (
                              <div className="col-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label className="label-control">
                                    Broker's name
                                  </label>
                                  <Controller
                                    name="intermediary_name"
                                    control={control}
                                    render={({ field }) => (
                                      <DropDownListComponent
                                        {...field}
                                        dataSource={getBrokerName}
                                        id="intermediary_name"
                                        name="intermediary_name"
                                        fields={{ text: "name", value: "name" }}
                                        allowFiltering={true}
                                        filterType={"Contains"}
                                        value={field.value}
                                        placeholder="Select Broker's name"
                                        change={(event) => {
                                          if (event.itemData != null) {
                                            field.onChange(event.itemData.name);
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                  {errors.intermediary_name && (
                                    <ErrorLabel
                                      message={
                                        errors.intermediary_name?.message
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ) : competitionIntermediaryType != null ? (
                              <div className="col-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label className="label-control">
                                    Intermediary Name
                                  </label>
                                  <input
                                    className="form-control"
                                    id="intermediary_name"
                                    name="intermediary_name"
                                    placeholder="Enter Other Reason"
                                    {...register("intermediary_name")}
                                  />
                                  {errors.intermediary_name && (
                                    <ErrorLabel
                                      message={
                                        errors.intermediary_name?.message
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control pl-0">
                                  Insurer Name&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <Controller
                                  name="opportunity_insurer_id"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      dataSource={departmentWiseInsurer}
                                      id="opportunity_insurer_id"
                                      name="opportunity_insurer_id"
                                      fields={{ text: "name", value: "id" }}
                                      allowFiltering={true}
                                      filterType={"Contains"}
                                      placeholder="Select Insurance Company"
                                      value={field.value}
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          // setInsurerCompany(event.itemData.name)
                                          field.onChange(event.itemData.id);
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    required: "Insurer Name is required",
                                  }}
                                />
                                {errors.opportunity_insurer_id && (
                                  <ErrorLabel
                                    message={
                                      errors.opportunity_insurer_id?.message
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Net Amount&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  id="net_amount"
                                  name="net_amount"
                                  placeholder="Enter Net Amount"
                                  onKeyPress={(event) => {
                                    const value = event.target.value;
                                    const newValue = value + event.key;
                                    if (!/^\d+(\.\d{0,3})?$/.test(newValue)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    const pastedText =
                                      event.clipboardData.getData("text/plain");
                                    const textWithoutCommas =
                                      pastedText.replace(/,/g, "");
                                    if (!/^\d+$/.test(textWithoutCommas)) {
                                      event.preventDefault();
                                    } else {
                                      document.execCommand(
                                        "insertText",
                                        false,
                                        textWithoutCommas
                                      );
                                      event.preventDefault();
                                    }
                                  }}
                                  {...register("net_amount", {
                                    required: "Enter Net Amount",
                                  })}
                                />
                                {errors.net_amount && (
                                  <ErrorLabel
                                    message={errors.net_amount?.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  GST Amount&nbsp;
                                  <span className="danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  id="gst_amount"
                                  name="gst_amount"
                                  onKeyPress={(event) => {
                                    const value = event.target.value;
                                    const newValue = value + event.key;
                                    if (!/^\d+(\.\d{0,2})?$/.test(newValue)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  placeholder="Enter GST Amount"
                                  {...register("gst_amount")}
                                />
                                {errors.gst_amount && (
                                  <ErrorLabel
                                    message={errors.gst_amount?.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Total Premium
                                </label>
                                <input
                                  className="form-control"
                                  id="total_premium"
                                  name="total_premium"
                                  disabled={true}
                                  placeholder="Enter Net Amount"
                                  {...register("total_premium")}
                                />
                                {errors.total_premium && (
                                  <ErrorLabel
                                    message={errors.total_premium?.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control pl-0">
                                  Payment Sent to Insurer
                                </label>
                                <Controller
                                  name="payment_sent_to_insurer"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      id="payment_sent_to_insurer"
                                      name="payment_sent_to_insurer"
                                      dataSource={[
                                        {
                                          value: "Yes",
                                          title: "Yes",
                                        },
                                        {
                                          value: "No",
                                          title: "No",
                                        },
                                      ]}
                                      allowFiltering={false}
                                      placeholder="Select Value"
                                      value={field.value}
                                      fields={{ text: "title", value: "value" }}
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.value);
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control pl-0">
                                  Client Responsive
                                </label>
                                <Controller
                                  name="client_response"
                                  control={control}
                                  render={({ field }) => (
                                    <DropDownListComponent
                                      {...field}
                                      id="client_response"
                                      name="client_response"
                                      dataSource={[
                                        {
                                          value: "Yes",
                                          title: "Yes",
                                        },
                                        {
                                          value: "No",
                                          title: "No",
                                        },
                                      ]}
                                      allowFiltering={false}
                                      placeholder="Select Value"
                                      value={field.value}
                                      fields={{ text: "title", value: "value" }}
                                      change={(event) => {
                                        if (event.itemData != null) {
                                          field.onChange(event.itemData.value);
                                        }
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div className="form-group">
                                <label className="label-control">
                                  Sales Remarks
                                </label>
                                <input
                                  className="form-control"
                                  id="sales_remark"
                                  name="sales_remark"
                                  placeholder="Enter Sales Remark"
                                  disabled={
                                    userId == 1 ||
                                      userId ===
                                      proposalPoolViewDetails?.user_id ||
                                      userId ===
                                      proposalPoolViewDetails?.assigned_rm_id
                                      ? false
                                      : true
                                  }
                                  {...register("sales_remark")}
                                />
                                {errors.sales_remark && (
                                  <ErrorLabel
                                    message={errors.sales_remark?.message}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {proposalPoolViewDetails?.product_specialist_id != 0 &&
                          proposalPoolViewDetails?.product_specialist_id != "" &&
                          proposalPoolViewDetails?.product_specialist_id !=
                          null ? (
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Placement Remarks
                              </label>
                              <textarea
                                className="form-control"
                                id="opportunity_remark"
                                name="opportunity_remark"
                                disabled={
                                  userId == 1 ||
                                    // userId === proposalPoolViewDetails?.product_specialist_id
                                    proposalProductRight === true
                                    ? false
                                    : true
                                }
                                placeholder="Enter Placement Remark"
                                {...register("opportunity_remark", {
                                  required:
                                    quoteStatus == "Quote Rejected"
                                      ? "Placement Remark Required"
                                      : false,
                                })}
                              />
                              {errors.opportunity_remark && (
                                <ErrorLabel
                                  message={errors.opportunity_remark?.message}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {quoteStatus === "Proposal Lost" ||
                          quoteStatus === "Proposal Success" ? (
                          <>
                            <div className="col-12 col-md-4 col-lg-4">
                              <div class="form-group ">
                                <fieldset className="form-group">
                                  <label htmlFor="basicInputFile">
                                    Document&nbsp;
                                  </label>
                                  <div className="custom-file">
                                    <input
                                      type="file"
                                      className="custom-file-input"
                                      id="file"
                                      name="file"
                                      accept=".doc,.docx,.xlsx,.xls,.pdf,.zip"
                                      multiple={true}
                                      onChange={(event) => {
                                        if (event.target.files != null) {
                                          if (event.target.files.length > 15) {
                                            Swal.fire({
                                              title: "Upload Error",
                                              text: "Maximum 15 files are allowed.",
                                              icon: "error",
                                            });
                                            return;
                                          }

                                          var fileNames = "";
                                          var arrFiles = [];

                                          for (
                                            let i = 0;
                                            i < event.target.files.length;
                                            i++
                                          ) {
                                            let file = event.target.files[i];

                                            if (
                                              Math.round(file.size / 1024) >=
                                              CLAIM_FILE_SIZE
                                            ) {
                                              Swal.fire({
                                                title: "Error",
                                                icon: "error",
                                                html: CLAIM_FILE_ERROR_MESSAGE,
                                                confirmButtonColor:
                                                  PRIMARY_COLOR,
                                              });
                                              continue;
                                            }

                                            var ext = file.name
                                              .split(".")
                                              .pop();

                                            if (
                                              !checkFileExtension(ext, validExt)
                                            ) {
                                              Swal.fire({
                                                title: "Upload Error",
                                                text: "You can only upload Word, Excel, PDF & ZIP files",
                                                icon: "error",
                                              });
                                              continue;
                                            }

                                            arrFiles.push(file);
                                            fileNames += file.name + ", ";
                                          }
                                          setFiles(arrFiles);
                                          setUploadDocument(
                                            _.trim(fileNames, ", ")
                                          );
                                        }
                                      }}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="file"
                                    >
                                      {uploadDocument != null
                                        ? uploadDocument
                                        : "Choose file"}
                                    </label>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                            <div className="col-12 mt-1">
                              <div className="row">
                                {proposalPoolViewDetails != null &&
                                  proposalPoolViewDetails?.documents != null &&
                                  proposalPoolViewDetails?.documents.length >
                                  0 ? (
                                  proposalPoolViewDetails.documents.map(
                                    (objLink, index) => (
                                      <>
                                        <div className="col-12 col-md-4 col-xl-3">
                                          <div className="card shadow bg-light rounded mb-1 ">
                                            <div className='card-body d-flex break-word word-break justify-content-between py-1'>
                                              <a
                                                className="primary"
                                                style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                                                onClick={() => {
                                                  if (
                                                    objLink.full_url
                                                      ?.split(".")
                                                      .pop() == "pdf" ||
                                                    objLink.full_url
                                                      ?.split(".")
                                                      .pop() == "PDF" ||
                                                    objLink.full_url
                                                      ?.split(".")
                                                      .pop() == "png" ||
                                                    objLink.full_url
                                                      ?.split(".")
                                                      .pop() == "jpg" ||
                                                    objLink.full_url
                                                      ?.split(".")
                                                      .pop() == "jpeg" ||
                                                    objLink.full_url
                                                      ?.split(".")
                                                      .pop() == "gif"
                                                  ) {
                                                    setSelectedDocumentIndex(
                                                      objLink.full_url
                                                    );
                                                    setShowDocumentDetails(
                                                      true
                                                    );
                                                    setExtension(
                                                      objLink.full_url
                                                        ?.split(".")
                                                        .pop()
                                                    );
                                                    setDocumentDetails(objLink);
                                                  } else {
                                                    downloadFile(
                                                      objLink.full_url
                                                    );
                                                  }
                                                }}
                                              >
                                                {
                                                  objLink.actual_uploaded_file_name
                                                }
                                              </a>
                                              <span className="cursor-pointer">
                                                {" "}
                                                <FiTrash2
                                                  size={16}
                                                  className="primary"
                                                  onClick={() => {
                                                    Swal.fire({
                                                      icon: "warning",
                                                      text: "Are you sure, you want to delete this document?",
                                                      cancelButtonText:
                                                        "Cancel",
                                                      showCancelButton: true,
                                                    }).then(
                                                      ({ isConfirmed }) => {
                                                        if (isConfirmed) {
                                                          $(
                                                            "#progress-loader"
                                                          ).removeClass(
                                                            "hidden"
                                                          );
                                                          props.actionDeleteDocument(
                                                            {
                                                              policy_id:
                                                                proposalPoolViewDetails?.id,
                                                              dms_id:
                                                                objLink?.id,
                                                            }
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }}
                                                />
                                              </span>
                                              <a
                                                id="downloadLink"
                                                className="d-none"
                                              ></a>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {/* </div> */}
                        {closureType === "Standard" ? (
                              <>
                              <div className="col-12 col-md-12 col-lg-12 ">
                                  <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                    <span>
                                     {"Brokerage Details"}
                                    </span>
                                  </h4>
                                </div>

                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      OD Brokerage %
                                    </label>
                                    <input
                                      className="form-control"
                                      id="od_brokerage_percentage"
                                      name="od_brokerage_percentage"
                                      placeholder="OD Brokerage %"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("od_brokerage_percentage")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      OD Brokerage Amount
                                    </label>
                                    <input
                                      className="form-control"
                                      id="od_brokerage_amt"
                                      name="od_brokerage_amt"
                                      placeholder="Enter OD Brokerage"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("od_brokerage_amt")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      TP Brokerage %
                                    </label>
                                    <input
                                      className="form-control"
                                      id="tp_brokerage_percentage"
                                      name="tp_brokerage_percentage"
                                      placeholder="TP Brokerage"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("tp_brokerage_percentage")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      TP Brokerage Amount
                                    </label>
                                    <input
                                      className="form-control"
                                      id="tp_brokerage_amt"
                                      name="tp_brokerage_amt"
                                      placeholder="Enter TP Brokerage"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("tp_brokerage_amt")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                  <label className="label-control">
                                      Reward On
                                    </label>
                                    <div className="form-control d-flex align-items-center">
                                   <div className="mr-4">
                                    <input
                                      type="radio"
                                      id="reward_on"
                                      name="reward_on"
                                      value="Brokerage"
                                      {...register("reward_on")}
                                    />
                                      <label className="ml-1">
                                      Brokerage
                                    </label>
                                    </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="reward_on"
                                      name="reward_on"
                                      value="Premium"
                                      {...register("reward_on")}
                                    />
                                     <label className="ml-1">
                                      Premium
                                    </label>
                                  </div>
                                  
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Reward %
                                    </label>
                                    <input
                                      className="form-control"
                                      id="reward_percentage"
                                      name="reward_percentage"
                                      placeholder="Reward %"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("reward_percentage")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Reward Amount
                                    </label>
                                    <input
                                      className="form-control"
                                      id="reward_amt"
                                      name="reward_amt"
                                      placeholder="Enter Reward Amount"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("reward_amt")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Bonus %
                                    </label>
                                    <input
                                      className="form-control"
                                      id="bonus_percentage"
                                      name="bonus_percentage"
                                      placeholder="Bonus %"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("bonus_percentage")}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Bonus Amount
                                    </label>
                                    <input
                                      className="form-control"
                                      id="bonus_amt"
                                      name="bonus_amt"
                                      placeholder="Enter Bonus Amount"
                                      onKeyPress={handleBrokeragePercentage}
                                      {...register("bonus_amt")}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Total Brokerage Amt &nbsp;
                                      <span className="danger">*</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      id="total_brokerage_amt"
                                      name="total_brokerage_amt"
                                      placeholder="Total Brokerage"
                                      readOnly
                                      {...register("total_brokerage_amt")}
                                    />
                                  </div>
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}

                        {quoteStatus != null &&
                          quoteStatus === "Proposal Success" &&
                          closureType != null &&
                          (closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking" ||
                            closureType === "Co-broking") ? (
                          <>
                            <div className="col-12 col-md-12 col-lg-12 ">
                              <div class="accordion" id="co-insurer-accordion">
                                <div
                                  data-toggle="collapse"
                                  data-target="#co-insurer-details-card"
                                  aria-expanded="true"
                                  aria-controls="co-insurer-details-card"
                                >
                                  <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                    <span>
                                      {" "}
                                      {closureType != null &&
                                        closureType === "Co-insurer"
                                        ? "Co-Insurer Details"
                                        : closureType != null &&
                                          closureType === "Co-broking"
                                          ? "Co-Broking Details"
                                          : "Brokerage Details"}{" "}
                                    </span>
                                    <button type="button" className="btn">
                                      <IoIosArrowDown
                                        cursor="pointer"
                                        size={18}
                                      />
                                    </button>
                                  </h4>
                                </div>

                                <div
                                  id="co-insurer-details-card"
                                  class="collapse container col-12 col-md-12 col-lg-12 col-xl-12"
                                  aria-labelledby="headingOne"
                                  data-parent="#co-insurer-accordion"
                                >
                                  <div>
                                    <ul class="nav nav-pills">
                                      <li class="nav-item ml-auto">
                                        <button
                                          id="add_question"
                                          name="add_question"
                                          type="button"
                                          className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                          onClick={() => {
                                            const index = questionList.length; 
                                            var arrQuestionList = Object.assign(
                                              [],
                                              questionList
                                            );
                                            {
                                              closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking"
                                                ? arrQuestionList.push({
                                                    id: "",
                                                    insurer_id: "",
                                                    broker_id: "",
                                                    sgst: "",
                                                    cgst: "",
                                                    gross_premium: "",
                                                    share_premium_amount: "",
                                                    od_brokerage_percent: "",
                                                    od_brokerage_amount: "",
                                                    tp_brokerage_percent: "",
                                                    tp_brokerage_amount: "",
                                                    reward_on: "Brokerage",
                                                    reward_percent: "",
                                                    reward_amount: "",
                                                    bonus_percent: "",
                                                    bonus_amount: "",
                                                    share: "",
                                                    pinc_share: "",
                                                    premium: "",
                                                    type: "",
                                                    entity_type: "INSURER",
                                                  })
                                                : arrQuestionList.push({
                                                    id: "",
                                                    broker_id: index === 0 ? 620 : "",
                                                    sgst: "",
                                                    cgst: "",
                                                    gross_premium: "",
                                                    share_premium_amount: "",
                                                    od_brokerage_percent: "",
                                                    od_brokerage_amount: "",
                                                    tp_brokerage_percent: "",
                                                    tp_brokerage_amount: "",
                                                    reward_on: "Brokerage",
                                                    reward_percent: "",
                                                    reward_amount: "",
                                                    bonus_percent: "",
                                                    bonus_amount: "",
                                                    share: "",
                                                    pinc_share: "",
                                                    premium: "",
                                                    type: "",
                                                    entity_type: "BROKER",
                                                  });
                                              setQuestionList(arrQuestionList);
                                            }
                                          }}
                                        >
                                          Add
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                      <form
                                        noValidate
                                        autoComplete="Off"
                                        onSubmit={handleSubmit(onSubmit)}
                                      >
                                        <div className="form-body">
                                          {/* <div className="form-section"> */}
                                          {questionList != null &&
                                            questionList.length > 0 ? (
                                            questionList.map((item, index) => (
                                              <>
                                                <div className="border-bottom-primary px-0 py-2">
                                                  <div className="row">
                                                  {closureType !== null && closureType === "Co-insurer & Co-broking" ? <>
                                                    <div className="col-12 col-md-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                          Insurer/Broker&nbsp;
                                                          <span className="danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <Controller
                                                          name={`entity_type${index}`}
                                                          id={`entity_type${index}`}
                                                          control={control}
                                                          render={({
                                                            field,
                                                          }) => (
                                                            <DropDownListComponent
                                                              {...field}
                                                              id={`entity_type${index}`}
                                                              name={`entity_type${index}`}
                                                              dataSource={[
                                                                {
                                                                  name: "BROKER",
                                                                  id: "BROKER",
                                                                },
                                                                {
                                                                  name: "INSURER",
                                                                  id: "INSURER",
                                                                },
                                                              ]}
                                                              allowFiltering={
                                                                true
                                                              }
                                                              filterType={
                                                                "Contains"
                                                              }
                                                              placeholder="Select Entity"
                                                              value={item.entity_type}
                                                              fields={{
                                                                text: "name",
                                                                value: "id",
                                                              }}
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                if (
                                                                  event.target
                                                                    .value ===
                                                                  "" ||
                                                                  index.entity_type ===
                                                                  null
                                                                ) {
                                                                  $(
                                                                    `#error-${`entity_type${index}`}`
                                                                  ).removeClass(
                                                                    "d-none"
                                                                  );
                                                                } else {
                                                                  $(
                                                                    `#error-${`entity_type${index}`}`
                                                                  ).addClass(
                                                                    "d-none"
                                                                  );
                                                                }
                                                                var tempMobileArray =
                                                                  Object.assign(
                                                                    [],
                                                                    questionList
                                                                  );
                                                                var objMobile =
                                                                  Object.assign(
                                                                    {},
                                                                    tempMobileArray[
                                                                    index
                                                                    ]
                                                                  );
                                                                objMobile.entity_type =
                                                                  event.target.value;
                                                                tempMobileArray[
                                                                  index
                                                                ] = objMobile;
                                                                setQuestionList(
                                                                  tempMobileArray
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                        <label
                                                          id={`error-${`entity_type${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Select Entity
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </> : <></>}
                                                    {closureType != null &&
                                                      (closureType ===
                                                      "Co-insurer" || (closureType ==="Co-insurer & Co-broking" && item.entity_type === "INSURER")) ? (
                                                      <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group mb-1">
                                                          <label className="label-control">
                                                            Insurance
                                                            Company&nbsp;
                                                            <span className="danger">
                                                              *
                                                            </span>
                                                          </label>
                                                          <Controller
                                                            name={`insurer_id${index}`}
                                                            id={`insurer_id${index}`}
                                                            control={control}
                                                            render={({
                                                              field,
                                                            }) => (
                                                              <DropDownListComponent
                                                                {...field}
                                                                id={`insurer_id${index}`}
                                                                name={`insurer_id${index}`}
                                                                dataSource={
                                                                  getInsuranceSuccessList
                                                                }
                                                                allowFiltering={
                                                                  true
                                                                }
                                                                filterType={
                                                                  "Contains"
                                                                }
                                                                placeholder="Select Company name"
                                                                value={
                                                                  item.insurer_id
                                                                }
                                                                fields={{
                                                                  text: "insurer_name",
                                                                  value:
                                                                    "insurer_id",
                                                                }}
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  if (
                                                                    event.target
                                                                      .value ===
                                                                    "" ||
                                                                    index.insurer_id ===
                                                                    null
                                                                  ) {
                                                                    $(
                                                                      `#error-${`insurer_id${index}`}`
                                                                    ).removeClass(
                                                                      "d-none"
                                                                    );
                                                                  } else {
                                                                    $(
                                                                      `#error-${`insurer_id${index}`}`
                                                                    ).addClass(
                                                                      "d-none"
                                                                    );
                                                                  }
                                                                  var tempMobileArray =
                                                                    Object.assign(
                                                                      [],
                                                                      questionList
                                                                    );
                                                                  var objMobile =
                                                                    Object.assign(
                                                                      {},
                                                                      tempMobileArray[
                                                                      index
                                                                      ]
                                                                    );
                                                                  objMobile.insurer_id =
                                                                    event.target.value;
                                                                  tempMobileArray[
                                                                    index
                                                                  ] = objMobile;
                                                                  setQuestionList(
                                                                    tempMobileArray
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          />
                                                          <label
                                                            id={`error-${`insurer_id${index}`}`}
                                                            className="font-check-label font-small-2 text-danger error-msg d-none"
                                                          >
                                                            Select Company name
                                                          </label>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      (closureType === "Co-broking" || (closureType ==="Co-insurer & Co-broking" && item.entity_type === "BROKER")) ? <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group mb-1">
                                                          <label className="label-control">
                                                            Broker Name&nbsp;
                                                            <span className="danger">
                                                              *
                                                            </span>
                                                          </label>
                                                          <Controller
                                                            name={`broker_id${index}`}
                                                            id={`broker_id${index}`}
                                                            control={control}
                                                            render={({
                                                              field,
                                                            }) => (
                                                              <DropDownListComponent
                                                                {...field}
                                                                id={`broker_id${index}`}
                                                                name={`broker_id${index}`}
                                                                dataSource={
                                                                  getBrokerName
                                                                }
                                                                allowFiltering={
                                                                  true
                                                                }
                                                                filterType={
                                                                  "Contains"
                                                                }
                                                                placeholder="Select Broker name"
                                                                value={
                                                                  item.broker_id
                                                                }
                                                                fields={{
                                                                  text: "name",
                                                                  value: "id",
                                                                }}
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  if (
                                                                    event.target
                                                                      .value ===
                                                                    "" ||
                                                                    index.broker_id ===
                                                                    null
                                                                  ) {
                                                                    $(
                                                                      `#error-${`broker_id${index}`}`
                                                                    ).removeClass(
                                                                      "d-none"
                                                                    );
                                                                  } else {
                                                                    $(
                                                                      `#error-${`broker_id${index}`}`
                                                                    ).addClass(
                                                                      "d-none"
                                                                    );
                                                                  }
                                                                  var tempMobileArray =
                                                                    Object.assign(
                                                                      [],
                                                                      questionList
                                                                    );
                                                                  var objMobile =
                                                                    Object.assign(
                                                                      {},
                                                                      tempMobileArray[
                                                                      index
                                                                      ]
                                                                    );
                                                                  objMobile.broker_id =
                                                                    event.target.value;
                                                                  tempMobileArray[
                                                                    index
                                                                  ] = objMobile;
                                                                  setQuestionList(
                                                                    tempMobileArray
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          />
                                                          <label
                                                            id={`error-${`broker_id${index}`}`}
                                                            className="font-check-label font-small-2 text-danger error-msg d-none"
                                                          >
                                                            Select Broker name
                                                          </label>
                                                        </div>
                                                      </div> : <></>
                                                    )}
                                                    <div className="col-12 col-md-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                          Type&nbsp;
                                                          <span className="danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <Controller
                                                          name={`type${index}`}
                                                          id={`type${index}`}
                                                          control={control}
                                                          render={({
                                                            field,
                                                          }) => (
                                                            <DropDownListComponent
                                                              {...field}
                                                              id={`type${index}`}
                                                              name={`type${index}`}
                                                              dataSource={[
                                                                {
                                                                  name: "Lead",
                                                                  id: "Lead",
                                                                },
                                                                {
                                                                  name: "Follow",
                                                                  id: "Follow",
                                                                },
                                                              ]}
                                                              allowFiltering={
                                                                true
                                                              }
                                                              filterType={
                                                                "Contains"
                                                              }
                                                              placeholder="Select Type"
                                                              value={item.type}
                                                              fields={{
                                                                text: "name",
                                                                value: "id",
                                                              }}
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                if (
                                                                  event.target
                                                                    .value ===
                                                                  "" ||
                                                                  index.type ===
                                                                  null
                                                                ) {
                                                                  $(
                                                                    `#error-${`type${index}`}`
                                                                  ).removeClass(
                                                                    "d-none"
                                                                  );
                                                                } else {
                                                                  $(
                                                                    `#error-${`type${index}`}`
                                                                  ).addClass(
                                                                    "d-none"
                                                                  );
                                                                }
                                                                var tempMobileArray =
                                                                  Object.assign(
                                                                    [],
                                                                    questionList
                                                                  );
                                                                var objMobile =
                                                                  Object.assign(
                                                                    {},
                                                                    tempMobileArray[
                                                                    index
                                                                    ]
                                                                  );
                                                                objMobile.type =
                                                                  event.target.value;
                                                                tempMobileArray[
                                                                  index
                                                                ] = objMobile;
                                                                setQuestionList(
                                                                  tempMobileArray
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                        <label
                                                          id={`error-${`type${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Select Type
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-md-1">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                          Share %&nbsp;
                                                          <span className="danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`share${index}`}
                                                          name="share"
                                                          placeholder="Share %"
                                                          value={
                                                            item.share != null
                                                              ? String(
                                                                item.share
                                                              )
                                                              : "--"
                                                          }
                                                          onChange={(event) => {
                                                            if (
                                                              event.target
                                                                .value === "" ||
                                                              index.share ===
                                                              null
                                                            ) {
                                                              $(
                                                                `#error-${`share${index}`}`
                                                              ).removeClass(
                                                                "d-none"
                                                              );
                                                            } else {
                                                              $(
                                                                `#error-${`share${index}`}`
                                                              ).addClass(
                                                                "d-none"
                                                              );
                                                            }
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                index
                                                                ]
                                                              );
                                                            objMobile.share =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommasAndMultipleDots =
                                                              pastedText
                                                                .replace(
                                                                  /,/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /(\.\d*\.)/g,
                                                                  "."
                                                                );

                                                            if (
                                                              !/^\d+(\.\d*)?$/.test(
                                                                textWithoutCommasAndMultipleDots
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommasAndMultipleDots
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            const inputValue =
                                                              event.target
                                                                .value;
                                                            if (
                                                              !/^[0-9.]$/.test(
                                                                event.key
                                                              ) ||
                                                              (event.key ===
                                                                "." &&
                                                                inputValue.includes(
                                                                  "."
                                                                )) ||
                                                              (event.key ===
                                                                "0" &&
                                                                inputValue ===
                                                                "0") ||
                                                              (event.key ===
                                                                "." &&
                                                                inputValue.startsWith(
                                                                  "."
                                                                )) ||
                                                              (event.key ===
                                                                "." &&
                                                                inputValue ===
                                                                "")
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                            if (
                                                              event.key ===
                                                              "." &&
                                                              event.target.value.includes(
                                                                "."
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        <label
                                                          id={`error-${`share${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter % Share
                                                        </label>
                                                      </div>
                                                    </div>
                                                    {closureType === "Co-insurer & Co-broking" && 
                                                      <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                          PINC Share %&nbsp;
                                                          <span className="danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`pinc_share${index}`}
                                                          name="pinc_share"
                                                          placeholder="PINC Share %"
                                                          value={
                                                            item.share != null
                                                              ? String(
                                                                item.pinc_share
                                                              )
                                                              : "-"
                                                          }
                                                          onChange={(event) => {
                                                            if (
                                                              event.target
                                                                .value === "" ||
                                                              index.pinc_share ===
                                                              null
                                                            ) {
                                                              $(
                                                                `#error-${`pinc_share${index}`}`
                                                              ).removeClass(
                                                                "d-none"
                                                              );
                                                            } else {
                                                              $(
                                                                `#error-${`pinc_share${index}`}`
                                                              ).addClass(
                                                                "d-none"
                                                              );
                                                            }
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                index
                                                                ]
                                                              );
                                                            objMobile.pinc_share =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommasAndMultipleDots =
                                                              pastedText
                                                                .replace(
                                                                  /,/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /(\.\d*\.)/g,
                                                                  "."
                                                                );

                                                            if (
                                                              !/^\d+(\.\d*)?$/.test(
                                                                textWithoutCommasAndMultipleDots
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommasAndMultipleDots
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            const inputValue =
                                                              event.target
                                                                .value;
                                                            if (
                                                              !/^[0-9.]$/.test(
                                                                event.key
                                                              ) ||
                                                              (event.key ===
                                                                "." &&
                                                                inputValue.includes(
                                                                  "."
                                                                )) ||
                                                              (event.key ===
                                                                "0" &&
                                                                inputValue ===
                                                                "0") ||
                                                              (event.key ===
                                                                "." &&
                                                                inputValue.startsWith(
                                                                  "."
                                                                )) ||
                                                              (event.key ===
                                                                "." &&
                                                                inputValue ===
                                                                "")
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                            if (
                                                              event.key ===
                                                              "." &&
                                                              event.target.value.includes(
                                                                "."
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        <label
                                                          id={`error-${`pinc_share${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter PINC % Share
                                                        </label>
                                                      </div>
                                                    </div>
                                                    }
                                                    {
                                                      (closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking" || (closureType === "Co-broking" && item?.broker_id == 620)) && ( 
                                                       <> 
                                                      <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        Share OD Premium
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`share_od_premium_amount${index}`}
                                                          name="share_od_premium_amount"
                                                          placeholder="Enter Premium Amount"
                                                          readOnly
                                                          value={
                                                            item.share_od_premium_amount &&
                                                              String(
                                                                  item.share_od_premium_amount
                                                                )
                                                          }
                                                          onChange={(event) => {
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.share_od_premium_amount =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommas =
                                                              pastedText.replace(
                                                                /,/g,
                                                                ""
                                                              );
                                                            if (
                                                              !/^\d+$/.test(
                                                                textWithoutCommas
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommas
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        {/* <label
                                                          id={`error-${`share_od_premium_amount${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                         Share OD Premium Amount
                                                        </label> */}
                                                      </div>
                                                      </div>
                                                      <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        Share TP Premium
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`share_tp_premium_amount${index}`}
                                                          name="share_tp_premium_amount"
                                                          placeholder="Enter Premium Amount"
                                                          readOnly
                                                          value={
                                                            item.share_tp_premium_amount &&
                                                          String(
                                                                  item.share_tp_premium_amount
                                                                )
                                                          }
                                                          onChange={(event) => {
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.share_tp_premium_amount =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommas =
                                                              pastedText.replace(
                                                                /,/g,
                                                                ""
                                                              );
                                                            if (
                                                              !/^\d+$/.test(
                                                                textWithoutCommas
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommas
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        {/* <label
                                                          id={`error-${`share_tp_premium_amount${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Share TP Premium Amount
                                                        </label> */}
                                                      </div>
                                                      </div>
                                                       </> 
                                                       )
                                                    } 
                                                   
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                          Share Premium
                                                           <span className="danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`share_premium_amount${index}`}
                                                          name="share_premium_amount"
                                                          placeholder="Enter Premium Amount"
                                                          readOnly
                                                          value={
                                                            item.share_premium_amount !=
                                                              null
                                                              ? String(
                                                                item.share_premium_amount
                                                              )
                                                              : "--"
                                                          }
                                                          onChange={(event) => {
                                                            if (
                                                              event.target
                                                                .value === "" ||
                                                              index.share_premium_amount ===
                                                              null
                                                            ) {
                                                              $(
                                                                `#error-${`share_premium_amount${index}`}`
                                                              ).removeClass(
                                                                "d-none"
                                                              );
                                                            } else {
                                                              $(
                                                                `#error-${`share_premium_amount${index}`}`
                                                              ).addClass(
                                                                "d-none"
                                                              );
                                                            }
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                index
                                                                ]
                                                              );
                                                            objMobile.share_premium_amount =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommas =
                                                              pastedText.replace(
                                                                /,/g,
                                                                ""
                                                              );
                                                            if (
                                                              !/^\d+$/.test(
                                                                textWithoutCommas
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommas
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        <label
                                                          id={`error-${`share_premium_amount${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter Share Premium
                                                          Amount
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                          Gross Premium
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`gross_premium${index}`}
                                                          name="gross_premium"
                                                          placeholder="Enter Gross Premium"
                                                          value={
                                                            item.gross_premium !=
                                                              null
                                                              ? String(
                                                                item.gross_premium
                                                              )
                                                              : "--"
                                                          }
                                                          onChange={(event) => {
                                                            if (
                                                              event.target
                                                                .value >
                                                              index.gross_premium
                                                            ) {
                                                              $(
                                                                `#error-${`gross_premium${index}`}`
                                                              ).removeClass(
                                                                "d-none"
                                                              );
                                                            } else {
                                                              $(
                                                                `#error-${`gross_premium${index}`}`
                                                              ).addClass(
                                                                "d-none"
                                                              );
                                                            }
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                index
                                                                ]
                                                              );
                                                            objMobile.gross_premium =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommas =
                                                              pastedText.replace(
                                                                /,/g,
                                                                ""
                                                              );
                                                            if (
                                                              !/^\d+$/.test(
                                                                textWithoutCommas
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommas
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        <label
                                                          id={`error-${`gross_premium${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter Gross Premium
                                                        </label>
                                                      </div>
                                                    </div>
                                                    {
                                                     (((closureType === "Co-insurer" || closureType === "Co-insurer & Co-broking") && (brokeragePaidBy === "Follow" || (brokeragePaidBy === "Lead" && item.type === "Lead")))|| item?.broker_id == 620) && (
                                                          <>
                                                           <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        OD Brokerage %
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`od_brokerage_percent${index}`}
                                                          name="od_brokerage_percent"
                                                          placeholder="Enter OD Brokerage %"
                                                          value={
                                                            item.od_brokerage_percent && String(
                                                                  item.od_brokerage_percent
                                                                )
                                                          }
                                                          onKeyPress={handleBrokeragePercentage}
                                                          onChange={(event) => {
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.od_brokerage_percent =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        OD Brokerage Amount
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`od_brokerage_amount${index}`}
                                                          name="od_brokerage_amount"
                                                          placeholder="Enter OD Brokerage Amount"
                                                          value={
                                                            item.od_brokerage_amount &&
                                                            String(
                                                                  item.od_brokerage_amount
                                                                )  
                                                          }
                                                          onChange={(event) => {
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.od_brokerage_amount =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        {/* <label
                                                          id={`error-${`od_brokerage_amount${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter OD Brokerage Amount
                                                        </label> */}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        TP Brokerage %
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`tp_brokerage_percent${index}`}
                                                          name="tp_brokerage_percent"
                                                          placeholder="Enter TP Brokerage %"
                                                          value={
                                                            item.tp_brokerage_percent && String(
                                                                  item.tp_brokerage_percent
                                                                )     
                                                          }
                                                          onChange={(event) => {
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.tp_brokerage_percent =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onKeyPress={handleBrokeragePercentage}
                                                        />
                                                        {/* <label
                                                          id={`error-${`tp_brokerage_percent${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter TP Brokerage %
                                                        </label> */}
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        TP Brokerage Amount
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`tp_brokerage_amount${index}`}
                                                          name="tp_brokerage_amount"
                                                          placeholder="Enter TP Brokerage Amount"
                                                          value={
                                                            item.tp_brokerage_amount && String(
                                                                  item.tp_brokerage_amount
                                                                )
                                                          }
                                                          onChange={(event) => {
                                                            // if (
                                                            //   event.target
                                                            //     .value === "" ||
                                                            //   index.tp_brokerage_amount ===
                                                            //     null
                                                            // ) {
                                                            //   $(
                                                            //     `#error-${`tp_brokerage_amount${index}`}`
                                                            //   ).removeClass(
                                                            //     "d-none"
                                                            //   );
                                                            // } else {
                                                            //   $(
                                                            //     `#error-${`tp_brokerage_amount${index}`}`
                                                            //   ).addClass(
                                                            //     "d-none"
                                                            //   );
                                                            // }
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.tp_brokerage_amount =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommas =
                                                              pastedText.replace(
                                                                /,/g,
                                                                ""
                                                              );
                                                            if (
                                                              !/^\d+$/.test(
                                                                textWithoutCommas
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommas
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        {/* <label
                                                          id={`error-${`tp_brokerage_amount${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                          Enter 0 if nothing to declare
                                                        </label> */}
                                                      </div>
                                                    </div>
                                                          <div className="col-12 col-md-2 col-lg-2">
                                                            <div className="form-group">
                                                              <label className="label-control">
                                                                Reward On
                                                              </label>
                                                              <div className="form-control d-flex align-items-center">
                                                                <div className="mr-1">
                                                                  <input
                                                                    type="radio"
                                                                    id={`reward_on_brokerage${index}`}
                                                                    name={`reward_on${index}`}  // Ensure unique name for each group
                                                                    value="Brokerage"
                                                                    checked={item.reward_on === 'Brokerage'}  // Conditionally set as checked
                                                                    onChange={(event) => {
                                                                      let tempMobileArray = [...questionList];  // Create a shallow copy of the array
                                                                      tempMobileArray[index] = {
                                                                        ...tempMobileArray[index],  // Copy the existing object
                                                                        reward_on: event.target.value  // Set the new value for reward_on
                                                                      };
                                                                      setQuestionList(tempMobileArray);  // Update the state
                                                                    }}
                                                                  />
                                                                  <label className="ml-1">
                                                                    Brokerage
                                                                  </label>
                                                                </div>
                                                                <div>
                                                                  <input
                                                                    type="radio"
                                                                    id={`reward_on_premium${index}`}
                                                                    name={`reward_on${index}`}  // Ensure unique name for each group
                                                                    value="Premium"
                                                                    checked={item.reward_on === 'Premium'}  // Conditionally set as checked
                                                                    onChange={(event) => {
                                                                      let tempMobileArray = [...questionList];  // Create a shallow copy of the array
                                                                      tempMobileArray[index] = {
                                                                        ...tempMobileArray[index],  // Copy the existing object
                                                                        reward_on: event.target.value  // Set the new value for reward_on
                                                                      };
                                                                      setQuestionList(tempMobileArray);  // Update the state
                                                                    }}
                                                                  />
                                                                  <label className="ml-1">
                                                                    Premium
                                                                  </label>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-md-2 col-lg-2">
                                                            <div className="form-group">
                                                              <label className="label-control">
                                                                Reward %
                                                              </label>
                                                              <input
                                                                className="form-control"
                                                                id={`reward_percent${index}`}
                                                                name="reward_percent"
                                                                placeholder="Reward %"
                                                                onKeyPress={handleBrokeragePercentage}
                                                                value={
                                                                  item.reward_percent && String(
                                                                    item.reward_percent
                                                                  )
                                                                }
                                                                onChange={(event) => {
                                                                  var tempMobileArray =
                                                                    Object.assign(
                                                                      [],
                                                                      questionList
                                                                    );
                                                                  var objMobile =
                                                                    Object.assign(
                                                                      {},
                                                                      tempMobileArray[
                                                                      index
                                                                      ]
                                                                    );
                                                                  objMobile.reward_percent =
                                                                    event.target.value;
                                                                  tempMobileArray[
                                                                    index
                                                                  ] = objMobile;
                                                                  setQuestionList(
                                                                    tempMobileArray
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-md-2 col-lg-2">
                                                            <div className="form-group">
                                                              <label className="label-control">
                                                                Reward Amount
                                                              </label>
                                                              <input
                                                                className="form-control"
                                                                id={`reward_amount${index}`}
                                                                name="reward_amount"
                                                                placeholder="Enter Reward Amount"
                                                                value={
                                                                  item.reward_amount && String(
                                                                    item.reward_amount
                                                                  )
                                                                }
                                                                onChange={(event) => {
                                                                  var tempMobileArray =
                                                                    Object.assign(
                                                                      [],
                                                                      questionList
                                                                    );
                                                                  var objMobile =
                                                                    Object.assign(
                                                                      {},
                                                                      tempMobileArray[
                                                                      index
                                                                      ]
                                                                    );
                                                                  objMobile.reward_amount =
                                                                    event.target.value;
                                                                  tempMobileArray[
                                                                    index
                                                                  ] = objMobile;
                                                                  setQuestionList(
                                                                    tempMobileArray
                                                                  );
                                                                }}
                                                                onPaste={(event) => {
                                                                  const pastedText =
                                                                    event.clipboardData.getData(
                                                                      "text/plain"
                                                                    );
                                                                  const textWithoutCommas =
                                                                    pastedText.replace(
                                                                      /,/g,
                                                                      ""
                                                                    );
                                                                  if (
                                                                    !/^\d+$/.test(
                                                                      textWithoutCommas
                                                                    )
                                                                  ) {
                                                                    event.preventDefault();
                                                                  } else {
                                                                    document.execCommand(
                                                                      "insertText",
                                                                      false,
                                                                      textWithoutCommas
                                                                    );
                                                                    event.preventDefault();
                                                                  }
                                                                }}
                                                                onKeyPress={(
                                                                  event
                                                                ) => {
                                                                  if (
                                                                    !/[0-9]/.test(
                                                                      event.key
                                                                    )
                                                                  ) {
                                                                    event.preventDefault();
                                                                  }
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-md-2 col-lg-2">
                                                            <div className="form-group">
                                                              <label className="label-control">
                                                                Bonus %
                                                              </label>
                                                              <input
                                                                className="form-control"
                                                                id={`bonus_percent${index}`}
                                                                name="bonus_percent"
                                                                placeholder="Bonus %"
                                                                onKeyPress={handleBrokeragePercentage}
                                                                value={
                                                                  item.bonus_percent && String(
                                                                    item.bonus_percent
                                                                  )
                                                                }
                                                                onChange={(event) => {
                                                                  var tempMobileArray =
                                                                    Object.assign(
                                                                      [],
                                                                      questionList
                                                                    );
                                                                  var objMobile =
                                                                    Object.assign(
                                                                      {},
                                                                      tempMobileArray[
                                                                      index
                                                                      ]
                                                                    );
                                                                  objMobile.bonus_percent =
                                                                    event.target.value;
                                                                  tempMobileArray[
                                                                    index
                                                                  ] = objMobile;
                                                                  setQuestionList(
                                                                    tempMobileArray
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-md-2 col-lg-2">
                                                            <div className="form-group">
                                                              <label className="label-control">
                                                                Bonus Amount
                                                              </label>
                                                              <input
                                                                className="form-control"
                                                                id={`bonus_amount${index}`}
                                                                name="bonus_amount"
                                                                placeholder="Enter Bonus Amount"
                                                                onKeyPress={handleBrokeragePercentage}
                                                                value={
                                                                  item.bonus_amount && String(
                                                                    item.bonus_amount
                                                                  )
                                                                }
                                                                // {...register("bonus_amt")}
                                                                onChange={(event) => {
                                                                  var tempMobileArray =
                                                                    Object.assign(
                                                                      [],
                                                                      questionList
                                                                    );
                                                                  var objMobile =
                                                                    Object.assign(
                                                                      {},
                                                                      tempMobileArray[
                                                                      index
                                                                      ]
                                                                    );
                                                                  objMobile.bonus_amount =
                                                                    event.target.value;
                                                                  tempMobileArray[
                                                                    index
                                                                  ] = objMobile;
                                                                  setQuestionList(
                                                                    tempMobileArray
                                                                  );
                                                                }}
                                                                readOnly
                                                              />
                                                            </div>
                                                          </div>
                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                         Total Brokerage Amount
                                                          <span className="danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`total_brokerage_amt${index}`}
                                                          name="total_brokerage_amt"
                                                          placeholder="Total Brokerage"
                                                          readOnly
                                                          value={
                                                            item.total_brokerage_amt !=
                                                            null
                                                              ? String(
                                                                  item.total_brokerage_amt
                                                                )
                                                              : "--"
                                                          }
                                                          onChange={(event) => {
                                                            // if (
                                                            //   event.target
                                                            //     .value >
                                                            //   index.total_brokerage_amt
                                                            // ) {
                                                            //   $(
                                                            //     `#error-${`total_brokerage_amt${index}`}`
                                                            //   ).removeClass(
                                                            //     "d-none"
                                                            //   );
                                                            // } else {
                                                            //   $(
                                                            //     `#error-${`total_brokerage_amt${index}`}`
                                                            //   ).addClass(
                                                            //     "d-none"
                                                            //   );
                                                            // }
                                                            var tempMobileArray =
                                                              Object.assign(
                                                                [],
                                                                questionList
                                                              );
                                                            var objMobile =
                                                              Object.assign(
                                                                {},
                                                                tempMobileArray[
                                                                  index
                                                                ]
                                                              );
                                                            objMobile.total_brokerage_amt =
                                                              event.target.value;
                                                            tempMobileArray[
                                                              index
                                                            ] = objMobile;
                                                            setQuestionList(
                                                              tempMobileArray
                                                            );
                                                          }}
                                                          onPaste={(event) => {
                                                            const pastedText =
                                                              event.clipboardData.getData(
                                                                "text/plain"
                                                              );
                                                            const textWithoutCommas =
                                                              pastedText.replace(
                                                                /,/g,
                                                                ""
                                                              );
                                                            if (
                                                              !/^\d+$/.test(
                                                                textWithoutCommas
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            } else {
                                                              document.execCommand(
                                                                "insertText",
                                                                false,
                                                                textWithoutCommas
                                                              );
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                        />
                                                        {/* <label
                                                          id={`error-${`total_brokerage_amt${index}`}`}
                                                          className="font-check-label font-small-2 text-danger error-msg d-none"
                                                        >
                                                        Total Brokerage
                                                        </label> */}
                                                      </div>
                                                    </div>
                                                          </>
                                                        )
                                                    }
                                                   
                                                    <button
                                                      className="btn btn-icon btn-sm"
                                                      type="button"
                                                      onClick={() => {
                                                        Swal.fire({
                                                          icon: "warning",
                                                          text: "Are you sure, you want to delete?",
                                                          cancelButtonText:
                                                            "Cancel",
                                                          showCancelButton: true,
                                                        }).then(
                                                          ({ isConfirmed }) => {
                                                            if (isConfirmed) {
                                                              const updatedList =
                                                                [
                                                                  ...questionList,
                                                                ];
                                                              updatedList.splice(
                                                                index,
                                                                1
                                                              );
                                                              setQuestionList(
                                                                updatedList
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <FiTrash2
                                                        size={16}
                                                        className="primary"
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ))
                                          ) : (
                                            <></>
                                          )}
                                          {/* </div> */}
                                          {addQuestion === true ? (
                                            <>
                                              <div className="row">
                                                <div className="col-12 col-md-3 col-lg-3">
                                                  <div className="form-group mb-1">
                                                    <label className="label-control">
                                                      Insurance Company
                                                    </label>
                                                    <Controller
                                                      name="insurer_id"
                                                      id={`insurer_id`}
                                                      control={control}
                                                      render={({ field }) => (
                                                        <DropDownListComponent
                                                          {...field}
                                                          id={`insurer_id`}
                                                          name="insurer_id"
                                                          dataSource={
                                                            coInsurerGetInsurer
                                                          }
                                                          allowFiltering={true}
                                                          filterType={
                                                            "Contains"
                                                          }
                                                          placeholder="Select Company name"
                                                          value={field.value}
                                                          fields={{
                                                            text: "name",
                                                            value: "insurer_id",
                                                          }}
                                                          onChange={(event) => {
                                                            // if (event.itemData != null) {
                                                            //     field.onChange(event.itemData.id)
                                                            //     setSelectedCompanyValue(event.itemData.id)
                                                            // }
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-3 col-lg-3">
                                                  <div className="form-group mb-1">
                                                    <label className="label-control">
                                                      Broker Name
                                                    </label>
                                                    <Controller
                                                      name="broker_id"
                                                      id={`broker_id`}
                                                      control={control}
                                                      render={({ field }) => (
                                                        <DropDownListComponent
                                                          {...field}
                                                          id={`broker_id`}
                                                          name="broker_id"
                                                          dataSource={
                                                            getBrokerName
                                                          }
                                                          allowFiltering={true}
                                                          filterType={
                                                            "Contains"
                                                          }
                                                          placeholder="Select Broker name"
                                                          value={field.value}
                                                          fields={{
                                                            text: "name",
                                                            value: "id",
                                                          }}
                                                          onChange={(event) => {
                                                            // if (event.itemData != null) {
                                                            //     field.onChange(event.itemData.id)
                                                            //     setSelectedCompanyValue(event.itemData.id)
                                                            // }
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-2">
                                                  <div className="form-group mb-1">
                                                    <label className="label-control">
                                                      Type
                                                    </label>
                                                    <Controller
                                                      name="type"
                                                      id={`type`}
                                                      control={control}
                                                      render={({ field }) => (
                                                        <DropDownListComponent
                                                          {...field}
                                                          id={`type`}
                                                          name="type"
                                                          dataSource={[
                                                            {
                                                              name: "Lead",
                                                              id: "Lead",
                                                            },
                                                            {
                                                              name: "Follow",
                                                              id: "Follow",
                                                            },
                                                          ]}
                                                          allowFiltering={true}
                                                          filterType={
                                                            "Contains"
                                                          }
                                                          placeholder="Select Company name"
                                                          value={field.value}
                                                          fields={{
                                                            text: "name",
                                                            value: "id",
                                                          }}
                                                          onChange={(event) => {
                                                            // if (event.itemData != null) {
                                                            //     field.onChange(event.itemData.id)
                                                            //     setSelectedCompanyValue(event.itemData.id)
                                                            // }
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-1">
                                                  <div className="form-group mb-1">
                                                    <label className="label-control">
                                                      Share %
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id={`share`}
                                                      name="share"
                                                      placeholder="Share %"
                                                      onKeyPress={(event) => {
                                                        if (
                                                          !/[0-9]/.test(
                                                            event.key
                                                          )
                                                        ) {
                                                          event.preventDefault();
                                                        }
                                                      }}
                                                      {...register("share", {
                                                        required:
                                                          "Please enter Premium",
                                                      })}
                                                    />
                                                    {errors.share && (
                                                      <ErrorLabel
                                                        message={
                                                          errors.share?.message
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-2">
                                                  <div className="form-group mb-1">
                                                    <label className="label-control">
                                                      Share Premium
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id={`share_premium_amount`}
                                                      name="share_premium_amount"
                                                      placeholder="Enter Percent Share"
                                                      onKeyPress={(event) => {
                                                        if (
                                                          !/[0-9]/.test(
                                                            event.key
                                                          )
                                                        ) {
                                                          event.preventDefault();
                                                        }
                                                      }}
                                                      {...register(
                                                        "share_premium_amount",
                                                        {
                                                          required:
                                                            "Please enter Premium",
                                                        }
                                                      )}
                                                    />
                                                    {errors.share_premium_amount && (
                                                      <ErrorLabel
                                                        message={
                                                          errors
                                                            .share_premium_amount
                                                            ?.message
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-2">
                                                  <div className="form-group mb-1">
                                                    <label className="label-control">
                                                      Gross Premium
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      id={`gross_premium`}
                                                      name="gross_premium"
                                                      placeholder="Enter SGST"
                                                      onKeyPress={(event) => {
                                                        if (
                                                          !/[0-9]/.test(
                                                            event.key
                                                          )
                                                        ) {
                                                          event.preventDefault();
                                                        }
                                                      }}
                                                      {...register(
                                                        "gross_premium",
                                                        {
                                                          required:
                                                            "Please enter SGST",
                                                        }
                                                      )}
                                                    />
                                                    {errors.gross_premium && (
                                                      <ErrorLabel
                                                        message={
                                                          errors.gross_premium
                                                            ?.message
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </div>

                                                {
                                                     (closureType === "Co-insurer") && ( 
                                                <>
                                                           <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        OD Brokerage %
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`od_brokerage_percent`}
                                                          name="od_brokerage_percent"
                                                          placeholder="Enter OD Brokerage %"
                                                          onKeyPress={handleBrokeragePercentage}
                                                          {...register(
                                                            "od_brokerage_percent",
                                                          )}
                                                        />
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        OD Brokerage Amount
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`od_brokerage_amount`}
                                                          name="od_brokerage_amount"
                                                          placeholder="Enter OD Brokerage Amount"
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          {...register(
                                                            "od_brokerage_amount",
                  
                                                          )}
                                                        />
                                                        
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        TP Brokerage %
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`tp_brokerage_percent`}
                                                          name="tp_brokerage_percent"
                                                          placeholder="Enter TP Brokerage %"
                                                          onKeyPress={handleBrokeragePercentage}
                                                          {...register("tp_brokerage_percent", {
                                                          
                                                          })}
                                                        />
                                                        
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                        TP Brokerage Amount
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`tp_brokerage_amount`}
                                                          name="tp_brokerage_amount"
                                                          placeholder="Enter TP Brokerage Amount"
                                                          
                                                          
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}
                                                          {...register("tp_brokerage_amount", {
                                                            
                                                          })}
                                                        />
                                                   
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-md-2 col-lg-2">
                                                      <div className="form-group mb-1">
                                                        <label className="label-control">
                                                         Total Brokerage Amount
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          id={`total_brokerage_amt`}
                                                          name="total_brokerage_amt"
                                                          placeholder="Total Brokerage"
                                                          readOnly
                                                          onKeyPress={(
                                                            event
                                                          ) => {
                                                            if (
                                                              !/[0-9]/.test(
                                                                event.key
                                                              )
                                                            ) {
                                                              event.preventDefault();
                                                            }
                                                          }}

                                                          {...register("total_brokerage_amt", {
                                                           
                                                          })}
                                                        />
                                                        
                                                      </div>
                                                    </div>
                                                          </>
                                                        )
                                                    }
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="row mt-2">
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <label className="label-control">
                                                Total Share
                                                <b className="ml-1">
                                                  {Number(
                                                    totalSharePercent
                                                  ).toFixed(2)}{" "}
                                                  %
                                                </b>
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <label className="label-control">
                                                Total Share Premium Amount 
                                                <b className="ml-1">
                                                  {Number(
                                                    totalSharePremiumValue
                                                  ).toFixed(2)}
                                                </b>
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <label className="label-control">
                                                {" "}
                                                Total Gross Premium
                                                <b className="ml-1">
                                                  {Number(
                                                    totalGrossPremiumValue
                                                  ).toFixed(2)}
                                                </b>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="form-actions border-0 float-none ">
                    <button
                      id="editproposal_pool"
                      className="btn btn-primary mr-1 shadow round text-uppercase"
                      type="submit"
                    >
                      <div className="d-flex align-items-center">
                        Submit
                        <span
                          id="proposalpool-edit-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                      onClick={(event) => {
                        event.preventDefault();
                        window.close();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddOpportunityPlacement
        {...props}
        show={showAddPlacement}
        data={placementData}
        departmentId={departmentId}
        opportunityId={opportunityId}
        detailsDataId={detailsData?.id}
        handleClose={() => {
          setShowAddPlacement(false);
          props.actionProposalGetPlacements({ policy_id: detailsData.id });
        }}
      />
      <DocumentIframe
        {...props}
        show={showDocumentDetails}
        documentDetails={selectedDocumentIndex}
        detailsOfDocument={documentDetails}
        extension={extension}
        handleClose={() => {
          setShowDocumentDetails(false);
        }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  var ProposalPoolEditOpportunity = null;
  var ProposalQuoteStatus = null;
  var ProposalQuoteRejectStatus = null;
  var ProposalLostStatus = null;
  var ProposalPlacementGet = null;
  var PolicyGroup = null;
  var InsuranceCompanyGroup = null;
  var IntermediaryType = null;
  var GetBrokerName = null;
  var TagUserList = null;
  var OpportunityQuestionList = null;
  var GetOpportunityQuestion = null;
  var ProbabilityGroup = null;
  var ClosureType = null;
  var ProposalPoolView = null;
  var GetAssignProductRM = null;
  var DepartmentWiseInsurer = null;
  var DeleteDocument = null;
  var InsuranceSuccessList = null;
  var Installment = null;

  if (
    state.ProposalPoolEditReducer != null &&
    Object.keys(state.ProposalPoolEditReducer).length > 0 &&
    state.ProposalPoolEditReducer.constructor === Object
  ) {
    switch (state.ProposalPoolEditReducer.tag) {
      case ACTION_PROPOSALPOOL_EDIT_SUCCESS:
        ProposalPoolEditOpportunity = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProposalPoolEditOpportunity };
      case ACTION_OPPORTUNITY_STATUS_SUCCESS:
        ProposalQuoteStatus = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProposalQuoteStatus };
      case ACTION_OPPORTUNITY_QUOTE_REJECT_STATUS_SUCCESS:
        ProposalQuoteRejectStatus = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProposalQuoteRejectStatus };
      case ACTION_OPPORTUNITY_LOST_STATUS_SUCCESS:
        ProposalLostStatus = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProposalLostStatus };
      case ACTION_OPPORTUNITY_PLACEMENTS_GET_SUCCESS:
        ProposalPlacementGet = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProposalPlacementGet };
      case ACTION_MASTER_INSURANCE_OPPORTUNITY_SUCCESS:
        InsuranceCompanyGroup = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { InsuranceCompanyGroup };
      case ACTION_MASTER_OPPORTUNITY_POLICY_SUCCESS:
        PolicyGroup = Object.assign({}, state.ProposalPoolEditReducer.data);
        delete state.ProposalPoolEditReducer;
        return { PolicyGroup };
      case ACTION_INTERMEDIARY_TYPE_SUCCESS:
        IntermediaryType = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { IntermediaryType };
      case ACTION_OPPORTUNITY_BROKER_NAME_SUCCESS:
        GetBrokerName = Object.assign({}, state.ProposalPoolEditReducer.data);
        delete state.ProposalPoolEditReducer;
        return { GetBrokerName };
      case ACTION_MASTER_PROBABILITY_SUCCESS:
        ProbabilityGroup = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProbabilityGroup };
      case ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS:
        OpportunityQuestionList = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { OpportunityQuestionList };
      case ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS:
        GetOpportunityQuestion = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { GetOpportunityQuestion };
      case ACTION_GET_CLOSURE_TYPE_SUCCESS:
        ClosureType = Object.assign({}, state.ProposalPoolEditReducer.data);
        delete state.ProposalPoolEditReducer;
        return { ClosureType };

       case ACTION_GET_TAG_USER_SUCCESS:
        TagUserList = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { TagUserList };

      case ACTION_PROPOSALPOOL_VIEW_SUCCESS:
        ProposalPoolView = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { ProposalPoolView };
      case ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS:
        GetAssignProductRM = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { GetAssignProductRM };
      case ACTION_DEPARTMENT_WISE_GET_INSURANCE_OPPORTUNITY_SUCCESS:
        DepartmentWiseInsurer = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { DepartmentWiseInsurer };
      case ACTION_DELETE_DOCUMENT_SUCCESS:
        DeleteDocument = Object.assign({}, state.ProposalPoolEditReducer.data);
        delete state.ProposalPoolEditReducer;
        return { DeleteDocument };
      case ACTION_INSURANCE_SUCCESS_LIST_SUCCESS:
        InsuranceSuccessList = Object.assign(
          {},
          state.ProposalPoolEditReducer.data
        );
        delete state.ProposalPoolEditReducer;
        return { InsuranceSuccessList };
      case ACTION_INSTALLMENT_SUCCESS:
        Installment = Object.assign({}, state.ProposalPoolEditReducer.data);
        delete state.ProposalPoolEditReducer;
        return { Installment };
      default:
    }
  }

  return {
    ProposalPoolEditOpportunity,
    ProposalQuoteStatus,
    ProposalQuoteRejectStatus,
    ProposalLostStatus,
    ProposalPlacementGet,
    InsuranceCompanyGroup,
    PolicyGroup,
    IntermediaryType,
    GetBrokerName,
    TagUserList,
    OpportunityQuestionList,
    GetOpportunityQuestion,
    ProbabilityGroup,
    ClosureType,
    ProposalPoolView,
    GetAssignProductRM,
    DepartmentWiseInsurer,
    DeleteDocument,
    InsuranceSuccessList,
    Installment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionProposalPoolEdit,
      actionProposalStatus,
      actionProposalQuoteRejectStatus,
      actionProposalLostStatus,
      actionProposalGetPlacements,
      actionPolicyGroup,
      actionInsuranceCompanyGroup,
      actionIntermediaryType,
      actionGetBrokerName,
      actionTagUserList,
      actionProbabilityGroup,
      actionOpportunityQuestionsList,
      actionGetOpportunityQuestions,
      actionClosureType,
      actionProposalPoolView,
      actionGetAssignProductRM,
      actionDepartmentInsuranceCompanyGroup,
      actionDeleteDocument,
      actionInsuranceListForSuccess,
      actionInstallment,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(AllProposalEdit)
);